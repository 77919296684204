import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function KeyboardShortcuts({ isLoggedIn }) {
    const navigate = useNavigate();

    useEffect(() => {
        const keysDown = {};

        function handleKeyDown(e) {
            keysDown[e.key] = true;

            if (keysDown["Control"] && keysDown["F1"]) {
                e.preventDefault();
                navigate("/deposit-withdraw");
            } else if (keysDown["Control"] && keysDown["F2"]) {
                e.preventDefault();
                navigate("/credit-in-out");
            }
        }

        function handleKeyUp(e) {
            keysDown[e.key] = false;
        }

        window.addEventListener("keydown", handleKeyDown);
        window.addEventListener("keyup", handleKeyUp);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
            window.removeEventListener("keyup", handleKeyUp);
        };
    }, [navigate]);

    return null;
}

export default KeyboardShortcuts;
