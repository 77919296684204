import axios from "axios";
import { Apiurl } from "./apiRoute";
import { showAlert } from "./alert_service";

function errorMethod(error) {
  console.log('error: ', error)
  if (error?.code === "ERR_NETWORK") {
    showAlert('Internal Server Error', "error")
    throw error?.message;
  } else if (error?.response) {
    showAlert(error?.response?.data?.message !== undefined ? error?.response?.data?.message : 'Internal Server Error', "error")
    throw error?.response?.data;
  } else {
    showAlert(error?.message !== undefined ? error?.message : 'Internal Server Error', "error")
    throw error?.message;
  }
}

export const getMethod = async (url, params = {}) => {
  const login_token = localStorage.getItem("token");

  const headers = login_token ? { Authorization: `Bearer ${login_token}` } : {};

  try {
    const response = await axios.get(Apiurl.routePath + url, {
      headers,
      params,
    });
    return response.data;
  } catch (error) {
    errorMethod(error)
  }
};

export const postMethod = async (url, params) => {
  const login_token = localStorage.getItem("token");

  if (login_token !== null) {
    var header = { Authorization: `Bearer ${login_token}` };
  }

  try {
    const response = await axios.post(Apiurl.routePath + url, params, {
      headers: header,
    });

    if(response?.data?.message !== undefined){
      showAlert(response?.data?.message, response?.data?.status === 200 ? "success" : "error");
    }else if(response?.data?.data?.message !== ""){
      showAlert(response?.data?.data?.message, response?.data?.status === 200 ? "success" : "error");
    }
    return response?.data;
  } catch (error) {
    errorMethod(error)
  }
};

export const deleteMethod = async (url) => {
  try {
    const login_token = localStorage.getItem("token");
    if (login_token !== null) {
      var headers = { Authorization: `Bearer ${login_token}` };
    }
    const response = await axios.delete(Apiurl.routePath + url, { headers });
    showAlert(response?.data?.message !== undefined ? response?.data?.message : response?.data?.data?.message, response?.data?.status === 200 ? "success" : "error");
    return response?.data;
  } catch (error) {
    errorMethod(error)
  }
};

export const putMethod = async (url, params) => {
  try {
    const login_token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${login_token}` };
    const response = await axios.put(Apiurl.routePath + url, params, { headers });
    showAlert(response?.data?.message !== undefined ? response?.data?.message : response?.data?.data?.message, response?.data?.status == 200 ? "success" : "error");
    return response;
  } catch (error) {
    errorMethod(error)
  }
};