import React, { useState } from "react";
import { postMethod } from "../../../services/base_service";
import { Apiurl } from "../../../services/apiRoute";
import Navbar from "../Navbar";
import { useNavigate } from "react-router-dom";
import '../../../css/sidebar.css';

const AddRoles = () => {
  const [role, setRole] = useState("");
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (role === "") {
        setErrors({ role: "Role Cannot be empty" });
      }
      if (role) {
        const response = await postMethod(Apiurl.role, { role: role });
        setRole("");
        setErrors({ ...errors, role: "" });
        // navigate('/roles')
      }
    } catch (error) {
      console.error("Error:", error);
      if (error.error === "Record Already Exists") {
        setErrors({ form: "User with this mobile number already exists." });
      } else
        setErrors({
          form: "An error occurred while adding the user. Please try again.",
        });
    }
  };

  return (
    <div>
      <Navbar />
      {/* <Sidebar /> */}
      <div id="main-content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row clearfix">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <h1>Add New Roles</h1>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-sm-12">
              <div className="card">
                <div className="body">
                  <form onSubmit={handleSubmit}>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">Role
                      <span className="text-danger" style={{ margin: "4px" }}>*</span>
                      </label>
                      
                      <div className="col-md-6 col-sm-10">
                        <input
                          type="text"
                          value={role}
                          onChange={(e) => setRole(e.target.value)}
                          className="form-control"
                          id="inputEmail3"
                          placeholder="Enter role name"
                        />
                        {errors && (
                          <p style={{ color: "red" }}>{errors.role}</p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label"></label>
                      <div className="col-md-6 col-sm-10">
                        <button
                          type="submit"
                          className="btn btn-primary btn_bg_color"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddRoles;
