import { BrowserRouter, Navigate, Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import Login from "./components/Auth/Login";
import UserCollections from "./components/pages/UserCollections";
import AddPayments from "./components/pages/AddPayments";
import Users from "./components/pages/Users";
import AddUser from "./components/pages/AddUser";
import Roles from "./components/pages/roles/Roles";
import AddRoles from "./components/pages/roles/AddRoles";
import AllTransactions from "./components/pages/AllTransactions";
import AccessControl from "./services/AccessControl";
import AddExpense from "./components/pages/expense/AddExpense";
import AddExpenseType from "./components/pages/expense_types/AddExpenseType";
import AllExpenseType from "./components/pages/expense_types/AllExpenseType";
import AllExpense from "./components/pages/expense/AllExpense";
import AddIncomeType from "./components/pages/income_types/AddIncomeType";
import IncomeType from "./components/pages/income_types/IncomeTypes";
import AddIncome from "./components/pages/income/AddIncome";
import Incomes from "./components/pages/income/Incomes";
import Transfer from "./components/pages/Transfer";
import Dashboard from "./components/pages/Dashboard";
import Permissions from "./components/pages/roles/Permissions";
import AddAgency from "./components/pages/agency/AddAgency";
import AgencyList from "./components/pages/agency/AgencyList";
import AddInventory from "./components/pages/inventory/AddInventory";
import Inventory from "./components/pages/inventory/Inventory";
import DueBills from "./components/pages/due_bills/dueBills";
import AddDueBills from "./components/pages/due_bills/AddDueBills";
import CurrencyList from "./components/pages/currency/CurrencyList";
import AddCurrency from "./components/pages/currency/AddCurrency";
import BuySell from "./components/pages/currency/BuySell";
import BuySellList from "./components/pages/currency/BuySellList";
import CreditInOut from "./components/pages/credit-in-out/CreditInOut";
import CreditInOutList from "./components/pages/credit-in-out/CreditInOutList";
import AllCreditInOutList from "./components/pages/credit-in-out/AllCreditInOutList";
import DepositWithdrawList from "./components/pages/deposit_withdraw/DepositWithdrawList";
import DepositWithdraw from "./components/pages/deposit_withdraw/DepositWithdraw";
import AddTransfer from "./components/pages/account-transaction/AddTransfer";
import AllTransferList from "./components/pages/account-transaction/AllTransferList";
import KeyboardShortcuts from "./services/KeyboardShortcuts";
import UserTransferList from "./components/pages/UserTransferList";
import UserCollectionList from "./components/pages/UserCollectionList";
import AllAccountTransactionList from "./components/pages/AllAccountTransactionList";

function App() {
  const isLoggedIn = localStorage.getItem("token");

  return (
    <>
      <BrowserRouter>
        <KeyboardShortcuts isLoggedIn={isLoggedIn} />
        <Routes>
          <Route
            path="/login"
            element={isLoggedIn ? <Navigate to="/" replace /> : <Login />}
          ></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route element={<AccessControl />}>
            <Route path="/" element={<Dashboard />} />
            <Route path="/users" element={<Users />} />
            <Route path="/addusers" element={<AddUser />} />
            <Route path="/roles" element={<Roles />} />
            <Route path="/addroles" element={<AddRoles />} />
            <Route path="/addpayments" element={<AddPayments />} />
            <Route path="/user-collections" element={<UserCollections />} />
            <Route path="/user-collections-list" element={<UserCollectionList />} />
            <Route path="/alltransactions" element={<AllTransactions />} />
            <Route path="/addexpensetype" element={<AddExpenseType />} />
            <Route path="/allExpensetype" element={<AllExpenseType />} />
            <Route path="/addexpense" element={<AddExpense />} />
            <Route path="/allexpense" element={<AllExpense />} />
            <Route path="/addincometype" element={<AddIncomeType />} />
            <Route path="/allincometype" element={<IncomeType />} />
            <Route path="/addincome" element={<AddIncome />} />
            <Route path="/allincome" element={<Incomes />} />
            <Route path="/transfer" element={<Transfer />} />
            <Route path="/usertransferlist" element={<UserTransferList />} />
            <Route path="/permissions" element={<Permissions />} />
            <Route path="/add-agency" element={<AddAgency />} />
            <Route path="/agency" element={<AgencyList />} />
            <Route path="/add-inventory" element={<AddInventory />} />
            <Route path="/inventory" element={<Inventory />} />
            <Route path="/due-bills" element={<DueBills />} />
            <Route path="/add-due-bill" element={<AddDueBills />} />
            <Route path="/add-currency" element={<AddCurrency />} />
            <Route path="/currency-list" element={<CurrencyList />} />
            <Route path="/deposit-withdraw-list" element={<DepositWithdrawList />} />
            <Route path="/all-account-transaction-list" element={<AllAccountTransactionList />} />
            <Route path="/deposit-withdraw" element={<DepositWithdraw />} />
            <Route path="/buy-sell" element={<BuySell />} />
            <Route path="/buy-sell-list" element={<BuySellList />} />
            <Route path="/credit-in-out" element={<CreditInOut />} />
            <Route path="/credit-in-out-list" element={<CreditInOutList />} />
            <Route path="/all-credit-in-out-list" element={<AllCreditInOutList />} />
            <Route path="/addtransfer" element={<AddTransfer />} />
            <Route path="/transferlist" element={<AllTransferList />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
