import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import Pagination from "../../../services/Pagination";
import { deleteMethod, getMethod, putMethod } from "../../../services/base_service";
import { Apiurl } from "../../../services/apiRoute";
import { debounce } from "lodash";
import { Link } from "react-router-dom";
import { showConfirmationDialog } from "../../../services/alert_service";
import { Modal, ModalHeader } from "reactstrap";

const AllExpense = () => {
  const [expenseList, setExpenseList] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [inputTimeout, setInputTimeout] = useState(null);
  const [searchComp, setSearchComp] = useState("");
  const [modal, setModal] = useState(false);
  const [expenseTypes, setExpenseTypes] = useState([]);
  const [Users, setUsers] = useState([]);
  const [currencies, setCurrencies] = useState([])
  const [selected, setSelected] = useState(0);

  const [formValue, setFormValue] = useState({
    debit_from: 0,
    expense_type_id: 0,
    payment_type: "cash",
    currency_id: 0,
    amount: "",
    date: "",
    note: "",
  });

  const [errors, setErrors] = useState({
    debit_from: 0,
    expense_type_id: 0,
    currency_id: 0,
    amount: "",
    date: "",
    note: "",
  });

  const itemPerPage = 5

  const permissions = JSON.parse(localStorage.getItem("permissions"));
  const rolePermissions = permissions.find(
    (item) => item.table_name === "expenses"
  );
  const isAdmin = localStorage.getItem("isAdmin") === "true";

  const getExpenseList = async () => {
    setIsLoading(true);
    await getMethod(Apiurl.expense, {
      page: currentPage,
      pageSize: itemPerPage,
      search: searchQuery,
    })
      .then((res) => {
        setExpenseList(res.data.data);
        setTotalPages(res.data.total_page);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const setEditModal = (id) => {
    setErrors({});
    setModal(true);
    const selected = expenseList.find((item) => item.id === id);
    setSelected(selected.id);

    setFormValue({
      debit_from: selected.debit_from,
      expense_type_id: selected.expense_type_id,
      payment_type: "cash",
      currency_id: selected.currency_id,
      amount: atob(selected.amount),
      date: selected.date,
      note: selected.note,
    });
  };

  const getExpenseTypes = async () => {
    await getMethod(`${Apiurl.expense_type}?checkForPermission=false`)
      .then((res) => {
        setExpenseTypes(res.data.data);
      })
      .catch((error) => {
      });
  };

  const getUsers = async () => {
    await getMethod(`${Apiurl.filter_user_list}?checkForPermission=false`)
      .then((res) => {
        setUsers(res.data.data);
      })
      .catch((error) => {
      });
  };

  const getCurrencies = async () => {
    await getMethod(`${Apiurl.currency}/list?checkForPermission=false`).then((data) => {
      setCurrencies(data.data.currency)
    }).catch((error) => {
    })
  }


  const validationRules = {
    debit_from: "Debit from is required.",
    expense_type_id: "Expense Type is required.",
    payment_type: "cash",
    currency_id: "Currency Type is required.",
    amount: "Amount is required.",
    date: "Date is required.",
    note: "Note is required.",
  };

  const validateForm = () => {
    let formIsValid = true;
    const newErrors = {};
    Object.entries(validationRules).forEach(([key, message]) => {
      if (!formValue[key]) {
        newErrors[key] = message;
        formIsValid = false;
      }
    });
    setErrors(newErrors);
    return formIsValid;
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    try {
      if (validateForm()) {
        const response = await putMethod(
          `${Apiurl.expense}/${selected}`,
          formValue
        );
        getExpenseList();
        setFormValue({});
        setErrors({});
        setModal(!modal);
      }
    } catch (err) {
    }
  };

  const handleDelete = async (id) => {
    try {
      const confirmation = await showConfirmationDialog(
        "Are you sure you want to delete ?",
        "",
        "warning"
      );
      if (confirmation) {
        await deleteMethod(`${Apiurl.expense}/${id}`)
          .then((res) => {
            getExpenseList();
          })
          .catch((error) => {
          });
      }
    } catch (error) {
    }
  };


  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const delayedSearch = debounce(() => {
    getExpenseList();
  }, 300);

  useEffect(() => {
    delayedSearch();
  }, [currentPage, searchQuery]);

  const handleSearch = (search) => {
    setSearchComp(search);
    if (inputTimeout) clearTimeout(inputTimeout);
    setInputTimeout(
      setTimeout(() => {
        setSearchQuery(search);
        setCurrentPage(1);
      }, 500)
    );
  };

  useEffect(() => {
    getExpenseTypes();
    getUsers();
    getCurrencies()
  }, []);

  return (
    <div>
      <Navbar />
      <Modal isOpen={modal} toggle={() => setModal(!modal)}>
        <ModalHeader toggle={() => setModal(!modal)} className="d-block">
          <div className="container-fluid">
            <div className="block-header">
              <div className="row clearfix">
                <div className="col-lg-4 col-md-12 col-sm-12">
                  <h1 style={{ width: "270px" }}>Update Expense</h1>
                </div>
              </div>
            </div>
            <div className="row clearfix">
              <div className="col-sm-12">
                <div className="">
                  <div className="body">
                    <form onSubmit={handleEditSubmit}>
                      <div className="form-group row justify-content-between align-items-center">
                        <label
                          className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 col-form-label"
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          Select Debit From
                          <span
                            className="text-danger"
                            style={{ margin: "4px" }}
                          >
                            *
                          </span>
                        </label>
                        <select
                          name="debit_from"
                          value={formValue.debit_from}
                          onChange={handleChange}
                          className="form-control input-height col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12"
                          style={{ marginLeft: "8px" }}
                        >
                          <option value="">Select Users...</option>
                          {Users.map((user, i) => (
                            <option key={i} value={user.id}>
                              {user.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      {errors.debit_from && (
                        <p className="useredit_err">{errors.debit_from}</p>
                      )}
                      <div className="form-group row justify-content-between align-items-center">
                        <label
                          className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 col-form-label"
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          Select Expense Type
                          <span
                            className="text-danger"
                            style={{ margin: "4px" }}
                          >
                            *
                          </span>
                        </label>
                        <select
                          name="expense_type_id"
                          value={formValue.expense_type_id}
                          onChange={handleChange}
                          className="form-control input-height col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12"
                          style={{ marginLeft: "8px" }}
                        >
                          <option value="">Select types...</option>
                          {expenseTypes &&
                            expenseTypes.map((item, i) => (
                              <option key={i} value={item.id}>
                                {item.type}
                              </option>
                            ))}
                        </select>
                      </div>
                      {errors.expense_type_id && (
                        <p className="useredit_err">{errors.expense_type_id}</p>
                      )}
                      <div className="form-group row justify-content-between align-items-center">
                        <label
                          className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 col-form-label"
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          Select Currency Type
                          <span
                            className="text-danger"
                            style={{ margin: "4px" }}
                          >
                            *
                          </span>
                        </label>
                        <select
                          name="currency_id"
                          value={formValue.currency_id}
                          onChange={handleChange}
                          className="form-control input-height col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12"
                          style={{ marginLeft: "8px" }}
                        >
                          <option value="">Select types...</option>
                          {
                            currencies.map((item, i) => (
                              <option key={i} value={item.id}>{item.name}</option>
                            ))
                          }
                        </select>
                      </div>
                      {errors.currency_id && (
                        <p className="useredit_err">{errors.currency_id}</p>
                      )}
                      <div className="form-group row justify-content-between align-items-center">
                        <label
                          className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 col-form-label"
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          Amount
                          <span
                            className="text-danger"
                            style={{ margin: "4px" }}
                          >
                            *
                          </span>
                        </label>
                        <input
                          type="text"
                          value={formValue.amount}
                          onChange={handleChange}
                          className="form-control input-height col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12"
                          name="amount"
                          style={{ marginLeft: "8px" }}
                          placeholder="Enter Amount"
                        />
                      </div>
                      {errors.amount && (
                        <p className="useredit_err">{errors.amount}</p>
                      )}
                      <div className="form-group row justify-content-between align-items-center">
                        <label
                          className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 col-form-label"
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          Date
                          <span
                            className="text-danger"
                            style={{ margin: "4px" }}
                          >
                            *
                          </span>
                        </label>
                        <input
                          type="date"
                          value={formValue.date}
                          name="date"
                          onChange={handleChange}
                          className="form-control input-height col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12"
                          style={{ marginLeft: "8px" }}
                        >

                        </input>
                      </div>
                      {errors.date && (
                        <p className="useredit_err">{errors.date}</p>
                      )}
                      <div className="form-group row justify-content-between align-items-center">
                        <label
                          className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 col-form-label"
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          Note
                          <span
                            className="text-danger"
                            style={{ margin: "4px" }}
                          >
                            *
                          </span>
                        </label>
                        <input
                          type="text"
                          value={formValue.note}
                          name="note"
                          onChange={handleChange}
                          placeholder="Enter Amount"
                          className="form-control input-height col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12"
                          style={{ marginLeft: "8px" }}
                        >

                        </input>
                      </div>
                      {errors.note && (
                        <p className="useredit_err">{errors.note}</p>
                      )}
                      <div className="form-group d-flex justify-content-center">
                        <div className="col-12 text-center mt-2">
                          <button
                            type="submit"
                            className="btn btn-success btn_bg_color"
                            style={{ marginRight: "30px" }}
                          >
                            Submit
                          </button>
                          <div
                            className="btn btn-secondary"
                            style={{ backgroundColor: "grey" }}
                            onClick={() => {
                              setModal(false);
                              setErrors({});
                            }}
                          >
                            Cancel
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalHeader>
      </Modal>

      <div id="main-content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row clearfix">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <h1>All Expenses</h1>
              </div>
              <div className="col-lg-8 col-md-12 col-sm-12 text-lg-right">
                <div className="d-flex align-items-center justify-content-lg-end mt-4 mt-lg-0 flex-wrap vivify pullUp delay-550">
                  <div className="mb-3 mb-xl-0">
                    <Link to="/addexpense">
                      <span className="btn btn-dark">Add New</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-sm-12">

              <div className="card">
                <div className="body">

                  <div className="input-group">
                    <input
                      type="text"
                      name="search"
                      onChange={(e) => {
                        handleSearch(e.target.value);
                      }}
                      value={searchComp}
                      className="form-control"
                      placeholder="Search here..."
                    />
                    <div className="input-group-append">
                      <span className="input-group-text" id="search-mail">
                        <i className="fa fa-search text-info"></i>
                      </span>
                    </div>
                  </div>

                </div>
              </div>
              {isLoading ? (
                <span className="loader"></span>
              ) : (

                <>
                  <div className="table-responsive">
                    <table className="table table-hover table-custom spacing5">
                      <thead>
                        <tr>
                          <th>Sr.No</th>
                          <th>Expense Type</th>
                          <th>Debit From</th>
                          <th>Date</th>
                          <th>Currency</th>
                          <th>Amount</th>
                          <th>Transaction By</th>
                          <th>Note</th>
                          {(isAdmin ||
                            rolePermissions?.edit ||
                            rolePermissions?.delete) && <th>Action</th>}
                        </tr>
                      </thead>

                      <tbody>
                        {expenseList && expenseList.length > 0 ? (
                          expenseList.map((list, i) => (
                            <React.Fragment key={i}>
                              <tr>
                                <td>#{(currentPage - 1) * itemPerPage + 1 + i}</td>
                                <td>{list.expense_type.type}</td>
                                <td>{list.users.name}</td>
                                <td>{list.date}</td>
                                <td>{list.currency.name}</td>
                                <td>{atob(list.amount)}</td>
                                <td>{list?.transaction_by_users?.name || "-"}</td>
                                <td>{list.note || "-"}</td>
                                <td>
                                  {(isAdmin || rolePermissions?.edit) && (
                                    <button
                                      type="button"
                                      className="btn btn-default btn-sm"
                                      title="Edit"
                                      onClick={() => setEditModal(list.id)}
                                    >
                                      <i className="fa fa-pencil text-success edit-button"></i>
                                    </button>
                                  )}
                                  {(isAdmin || rolePermissions?.delete) && (
                                    <button
                                      type="button"
                                      className="btn btn-default btn-sm"
                                      title="Delete"
                                      onClick={() => handleDelete(list.id)}
                                    >
                                      <i className="fa fa-trash-o text-danger delete-button"></i>
                                    </button>
                                  )}
                                </td>
                              </tr>
                            </React.Fragment>
                          ))) : (
                          <tr>
                            <td style={{ borderTop: "none", textAlign: "center" }} colSpan={8}>No records found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {expenseList && expenseList.length > 0 && (
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={handlePageChange}
                    />
                  )}
                </>
              )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllExpense;
