import React from "react";
import { useForm } from "react-hook-form";
import Navbar from "../Navbar";
import { Apiurl } from "../../../services/apiRoute";
import { postMethod } from "../../../services/base_service";

const AddAgency = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  const handleAddAgency = async (data) => {
    try {
      const response = await postMethod(`${Apiurl.agency}/`, data);
      reset();
    } catch (error) {
    }
  };
  
  const handleChange = (e, key) => {
    const value = e.target.value;
    let trimmedValue;
    if (key === "mobile_no") {
      trimmedValue = value.slice(0, 10);
      setValue("mobile_no", trimmedValue);
    } else if (key === "gst_in") {
      trimmedValue = value.slice(0, 15);
      setValue("gst_in", trimmedValue);
    }
  };
  return (
    <div>
      <Navbar />
      <div id="main-content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row clearfix">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <h1>Add New Agency</h1>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-sm-12">
              <div className="card">
                <div className="body">
                  <form onSubmit={handleSubmit(handleAddAgency)}>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Name
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <input
                          type="text"
                          {...register("name", {
                            required: "Agency Name is Required.",
                          })}
                          className="form-control"
                          name="name"
                          id="inputEmail3"
                          placeholder="Enter Agency Name"
                        />
                        {errors.name && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.name.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Mobile No.
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <input
                          type="number"
                          {...register("mobile_no", {
                            required: "Mobile Number is Required.",
                            pattern: {
                              value: /^\d{10}$/,
                              message:
                                "Please enter a valid 10-digit Mobile Number",
                            },
                          })}
                          onChange={(e) => handleChange(e, "mobile_no")}
                          maxLength={10}
                          className="form-control"
                          name="mobile_no"
                          id="inputEmail3"
                          placeholder="Enter Mobile No."
                        />
                        {errors.mobile_no && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.mobile_no.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        GSTIN
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <input
                          type="text"
                          {...register("gst_in", {
                            required: "GST IN is Required.",
                            pattern: {
                              value: /^[0-9A-Za-z]{15}$/,
                              message: "Please enter a valid 15-character GSTIN",
                            },
                          })}
                          onChange={(e) => handleChange(e, "gst_in")}
                          className="form-control"
                          name="gst_in"
                          id="inputEmail3"
                          placeholder="Enter GSTIN"
                        />
                        {errors.gst_in && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.gst_in.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Address
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <input
                          type="text"
                          {...register("address", {
                            required: "Address is Required.",
                          })}
                          className="form-control"
                          name="address"
                          id="inputEmail3"
                          placeholder="Enter Address"
                        />
                        {errors.address && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.address.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label"></label>
                      <div className="col-md-6 col-sm-10">
                        <button
                          type="submit"
                          className="btn btn-success btn_bg_color"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAgency;
