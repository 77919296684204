import React, { useEffect, useState } from "react";
import "../../css/sidebar.css";
import { NavLink, useLocation } from "react-router-dom";
import { Header } from "../utils/NameString.js";
import { getMethod } from "../../services/base_service.js";
import { Apiurl } from "../../services/apiRoute.js";

const Sidebar = () => {
  const [isSidebarVisible, setSidebarVisible] = useState(true);
  const [width, setWidth] = useState(window.innerWidth);
  const [permissions, setPermissions] = useState([]);

  // const location = useLocation();

  const user = localStorage.getItem("username");
  const isAdmin = localStorage.getItem("isAdmin") === "true";

  const getPermissions = async () => {
    const role_id = await localStorage.getItem("role_id");
    await getMethod(
      `${Apiurl.rolePermit}/role?role_id=${role_id}?checkForPermission=false`
    )
      .then((res) => {
        localStorage.setItem("permissions", JSON.stringify(res.data.data));
        setPermissions(res.data.data);
      })
      .catch((error) => {
      });
  };

  // const hasSettingsPermission = () => {
  //   const settingsTables = [
  //     "appointment_types",
  //     "roles",
  //     "role_permissions",
  //     "expense_types",
  //     "income_types",
  //   ];
  //   return settingsTables.some((table) =>
  //     permissions.some(
  //       (perm) => perm.table_name === table && (perm.read || perm.create)
  //     )
  //   );
  // };

  const hasPermission = (permissions, table_name, action) => {
    const permission = permissions.find(
      (perm) => perm.table_name === table_name
    );
    return permission && permission[action];
  };

  const hasTablePermission = (tableName) => {
    const permission = permissions.find(
      (perm) => perm.table_name === tableName
    );
    if (permission) {
      return permission.read || permission.create;
    }
    return false;
  };

  // const expandActiveMenu = () => {
  //   const activeLink = location.pathname;
  //   const submenuLinks = document.querySelectorAll(".submenu-link");

  //   submenuLinks.forEach((link) => {
  //     console.log("fddfgdf", link.getAttribute("href"));
  //     if (link.getAttribute("href") === activeLink) {
  //       const parentSubMenu = link.closest(".submenu");
  //       if (parentSubMenu) {
  //         parentSubMenu.style.display = "block";
  //         const parentSpan = parentSubMenu.closest("li").querySelector("span");
  //         if (parentSpan) parentSpan.classList.add("active-menu-item");
  //       }
  //       const parentSubMenu1 = link.closest(".submenu1");
  //       if (parentSubMenu1 && link.getAttribute("href") === activeLink) {
  //         parentSubMenu1.style.display = "block";
  //         const parentSpan = parentSubMenu1.closest("li").querySelector("span");
  //         if (parentSpan) parentSpan.classList.add("active-menu-item");
  //       }
  //     }
  //   });
  // };

  function toggleSidebar() {
    setSidebarVisible(!isSidebarVisible);
  }

  function toggleSubMenu(event) {
    event.preventDefault();

    const parentLi = event.target.closest("li");
    const submenu = parentLi.querySelector(".submenu");
    const span = parentLi.querySelector("span");

    if (submenu.style.display === "block") {
      submenu.style.display = "none";
      span.classList.remove("active-menu-item");
    } else {
      const allSubmenus = document.querySelectorAll(".submenu");
      const allSubmenus1 = document.querySelectorAll(".submenu1");

      allSubmenus.forEach((submenu) => {
        submenu.style.display = "none";
      });
      allSubmenus1.forEach((submenu1) => {
        submenu1.style.display = "none";
      });

      const allSpans = document.querySelectorAll("#main-menu > li span");
      allSpans.forEach((span) => {
        span.classList.remove("active-menu-item");
      });

      submenu.style.display = "block";
      span.classList.add("active-menu-item");
    }
  }

  function toggleSubMenu1(event) {
    event.preventDefault();
    const parentLi = event.target.closest("li");
    // const li = parentLi.querySelector('ul > li')
    // li.style.backgroundColor = "yellow";
    // const a = parentLi.querySelector("a");
    // console.log('parentLi: ', parentLi)
    // a.style.backgroundColor = "red";
    const submenu = parentLi.querySelector(".submenu1");
    const span = parentLi.querySelector("span");

    if (submenu.style.display === "block") {
      submenu.style.display = "none";
      span.classList.remove("active-menu-item");
      // a.style.backgroundColor = "green";
    } else {
      const allSubmenus = document.querySelectorAll(".submenu1");
      allSubmenus.forEach((submenu) => {
        submenu.style.display = "none";
        // a.style.backgroundColor = "unset";
      });

      const allSpans = document.querySelectorAll("#main-menu > li span");
      allSpans.forEach((span) => {
        span.classList.remove("active-menu-item");
        // a.style.backgroundColor = "unset";
      });

      submenu.style.display = "block";
      span.classList.add("active-menu-item");
      // span.style.backgroundColor = "red";
      // a.style.backgroundColor = "blue";

    }
  }

  const handleSidebarClose = () => {
    if (width < 1270) setSidebarVisible(!isSidebarVisible);
  };

  const closeSidebar = () => {
    if (width <= 1270) {
      setSidebarVisible(false);
    } else {
      setSidebarVisible(true);
    }
  };

  const close = () => {
    setWidth(window.innerWidth);
  };
  
  // useEffect(() => {
  //   expandActiveMenu();
  // }, [location.pathname]);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [width]);

  useEffect(() => {
    closeSidebar();
    close();
  }, [width]);

  useEffect(() => {
    getPermissions();
  }, []);

  return (
    <div>
      {isSidebarVisible ? (
        <div
          id="left-sidebar"
          className="sidebar light_active sidebar_border scroll_sidebar"
        >
          <div className="navbar-brand">
            <div>
              <img
                src={Header.Logo_image}
                alt="hospital vLogo"
                className="img-fluid logo"
              />
              <span>{Header.Main_title}</span>
            </div>
            <button
              type="button"
              className=" btn btn-sm float-right show_button_opened"
              onClick={toggleSidebar}
            >
              <i className="fa fa-close"></i>
            </button>
          </div>
          <div className="user-account">
            <div className="user_div">
              <img
                src={Header.User_image}
                className="user-photo"
                alt="User Profile"
              />
            </div>
            <div className="dropdown">
              <span>{Header.Greetings}</span>
              <div
                className="dropdown-toggle user-name"
                data-toggle="dropdown"
              >
                <strong>{user}</strong>
              </div>
            </div>
          </div>
          <nav
            id="left-sidebar-nav"
            className="sidebar-nav"
            style={{ fontFamily: "Nunito" }}
          >
            <ul
              id="main-menu"
              className="metismenu animation-li-delay collapsing.in"
            >
              <li className="header">{Header.Sub_title}</li>
              <li>
                <NavLink
                  to="/"
                  className="submenu-link"
                  onClick={handleSidebarClose}
                >
                  <i className="fa fa-dashboard"></i>
                  Dashboard
                </NavLink>
              </li>
              {(isAdmin || hasTablePermission("deposit_withdrawals")) && (
                <li>
                  <a href="" className="has-arrow" onClick={toggleSubMenu}>
                    <i className="fa fa-user"></i>
                    <span>Deposit/Withdraw</span>
                  </a>
                  <ul className="submenu">
                    <li>
                      {(isAdmin ||
                        hasPermission(permissions, "deposit_withdrawals", "read")) && (
                          <NavLink
                            to="/deposit-withdraw-list"
                            className="submenu-link"
                            onClick={handleSidebarClose}
                          >
                            Deposit/Withdraw List
                          </NavLink>
                        )}
                    </li>
                    <li>
                      {(isAdmin ||
                        hasPermission(permissions, "deposit_withdrawals", "create")) && (
                          <NavLink
                            to="/deposit-withdraw"
                            className="submenu-link"
                            onClick={handleSidebarClose}
                          >
                            Add Deposit/Withdraw
                          </NavLink>
                        )}
                    </li>
                  </ul>
                </li>
              )}
              {(isAdmin || hasTablePermission("credit_in_out")) && (
                <li>
                  <a href="" className="has-arrow" onClick={toggleSubMenu}>
                    <i className="fa fa-credit-card"></i>
                    <span>Credit In/Out</span>
                  </a>
                  <ul className="submenu">
                    <li>
                      {(isAdmin ||
                        hasPermission(permissions, "credit_in_out", "read")) && (
                          <NavLink
                            to="/credit-in-out-list"
                            className="submenu-link"
                            onClick={handleSidebarClose}
                          >
                            Credit In/Out List
                          </NavLink>
                        )}
                    </li>
                    <li>
                      {(isAdmin ||
                        hasPermission(permissions, "credit_in_out", "read")) && (
                          <NavLink
                            to="/all-credit-in-out-list"
                            className="submenu-link"
                            onClick={handleSidebarClose}
                          >
                            All Credit In/Out List
                          </NavLink>
                        )}
                    </li>
                    <li>
                      {(isAdmin ||
                        hasPermission(permissions, "credit_in_out", "create")) && (
                          <NavLink
                            to="/credit-in-out"
                            className="submenu-link"
                            onClick={handleSidebarClose}
                          >
                            Credit In/Out
                          </NavLink>
                        )}
                    </li>
                  </ul>
                </li>
              )}
              {(isAdmin || hasTablePermission("mt5usdt_transaction")) && (
                <li>
                  <a href="" className="has-arrow" onClick={toggleSubMenu}>
                    <i className="fa fa-exchange"></i>
                    <span>Account Transaction</span>
                  </a>
                  <ul className="submenu">
                    <li>
                      {(isAdmin ||
                        hasPermission(permissions, "mt5usdt_transaction", "read")) && (
                          <NavLink
                            to="/transferlist"
                            className="submenu-link"
                            onClick={handleSidebarClose}
                          >
                            Transaction List
                          </NavLink>
                        )}
                    </li>
                    <li>
                      {(isAdmin ||
                        hasPermission(permissions, "mt5usdt_transaction", "create")) && (
                          <NavLink
                            to="/addtransfer"
                            className="submenu-link"
                            onClick={handleSidebarClose}
                          >
                            Add Transaction
                          </NavLink>
                        )}
                    </li>
                  </ul>
                </li>
              )}
              { (isAdmin || (hasTablePermission("deposit_withdrawals") && hasTablePermission("mt5usdt_transaction"))) && (
                <li>
                <NavLink
                  to="/all-account-transaction-list"
                  className="submenu-link"
                  onClick={handleSidebarClose}
                >
                  <i className="fa fa-list-ul"></i>
                  All Account Transaction List
                </NavLink>
              </li>
              )
              }
              {((isAdmin || hasTablePermission("users")) || (isAdmin || hasTablePermission("collections")) || (isAdmin || hasTablePermission("transfer"))) && (
                <li>
                  <a href="" className="has-arrow" onClick={toggleSubMenu}>
                    <i className="fa fa-user"></i>
                    <span>Users</span>
                  </a>
                  <ul className="submenu">
                    <li>
                      {(isAdmin ||
                        hasPermission(permissions, "users", "read")) && (
                          <NavLink
                            to="/users"
                            className="submenu-link"
                            onClick={handleSidebarClose}
                          >
                            All Users
                          </NavLink>
                        )}
                    </li>
                    <li>
                      {(isAdmin ||
                        hasPermission(permissions, "users", "create")) && (
                          <NavLink
                            to="/addusers"
                            className="submenu-link"
                            onClick={handleSidebarClose}
                          >
                            Add Users
                          </NavLink>
                        )}
                    </li>
                    <li>
                      {(isAdmin ||
                        hasPermission(permissions, "collections", "read")) && (
                          <NavLink
                            to="/user-collections"
                            className="submenu-link"
                            onClick={handleSidebarClose}
                          >
                            User Collections
                          </NavLink>
                        )}
                    </li>
                    <li>
                      {(isAdmin ||
                        hasPermission(permissions, "transfer", "read")) && (
                          <NavLink
                            to="/usertransferlist"
                            className="submenu-link"
                            onClick={handleSidebarClose}
                          >
                            Transfer List
                          </NavLink>
                        )}
                    </li>
                    <li>
                      {(isAdmin ||
                        hasPermission(permissions, "transfer", "create")) && (
                          <NavLink
                            to="/transfer"
                            className="submenu-link"
                            onClick={handleSidebarClose}
                          >
                            Transfer
                          </NavLink>
                        )}
                    </li>
                  </ul>
                </li>
              )}
              {(isAdmin || hasTablePermission("incomes")) && (
                <li>
                  <a href="" className="has-arrow" onClick={toggleSubMenu}>
                    <i className="fa fa-plus-circle"></i>
                    <span>Income</span>
                  </a>
                  <ul className="submenu">
                    <li>
                      {(isAdmin ||
                        hasPermission(permissions, "incomes", "read")) && (
                          <NavLink
                            to="/allincome"
                            className="submenu-link"
                            onClick={handleSidebarClose}
                          >
                            All Income
                          </NavLink>
                        )}
                    </li>
                    <li>
                      {(isAdmin ||
                        hasPermission(permissions, "incomes", "create")) && (
                          <NavLink
                            to="/addincome"
                            className="submenu-link"
                            onClick={handleSidebarClose}
                          >
                            Add Income
                          </NavLink>
                        )}
                    </li>
                  </ul>
                </li>
              )}
              {(isAdmin || hasTablePermission("expenses")) && (
                <li>
                  <a href="" className="has-arrow" onClick={toggleSubMenu}>
                    <i className="fa fa-minus-circle"></i>
                    <span>Expense</span>
                  </a>
                  <ul className="submenu">
                    <li>
                      {(isAdmin ||
                        hasPermission(permissions, "expenses", "read")) && (
                          <NavLink
                            to="/allexpense"
                            className="submenu-link"
                            onClick={handleSidebarClose}
                          >
                            All Expense
                          </NavLink>
                        )}
                    </li>
                    <li>
                      {(isAdmin ||
                        hasPermission(permissions, "expenses", "create")) && (
                          <NavLink
                            to="/addexpense"
                            className="submenu-link"
                            onClick={handleSidebarClose}
                          >
                            Add Expense
                          </NavLink>
                        )}
                    </li>
                  </ul>
                </li>
              )}
              {(isAdmin || hasTablePermission("buy_sell_currencies")) && (
                <li>
                  <a href="" className="has-arrow" onClick={toggleSubMenu}>
                    <i className="fa fa-money"></i>
                    <span>Currency (B/S)</span>
                  </a>
                  <ul className="submenu">
                    <li>
                      {(isAdmin ||
                        hasPermission(permissions, "buy_sell_currencies", "create")) && (
                          <NavLink
                            to="/buy-sell"
                            className="submenu-link"
                            onClick={handleSidebarClose}
                          >
                            Buy/Sell Currency
                          </NavLink>
                        )}
                    </li>
                    <li>
                      {(isAdmin ||
                        hasPermission(permissions, "buy_sell_currencies", "read")) && (
                          <NavLink
                            to="/buy-sell-list"
                            className="submenu-link"
                            onClick={handleSidebarClose}
                          >
                            Buy/Sell List
                          </NavLink>
                        )}
                    </li>
                  </ul>
                </li>
              )}
              {(isAdmin || hasTablePermission("inventory")) && (
                <li>
                  <a href="" className="has-arrow" onClick={toggleSubMenu}>
                    <i className="fa fa-ambulance"></i>
                    <span>Inventory</span>
                  </a>
                  <ul className="submenu">
                    <li>
                      {(isAdmin ||
                        hasPermission(permissions, "inventory", "read")) && (
                          <NavLink
                            to="/add-inventory"
                            className="submenu-link"
                            onClick={handleSidebarClose}
                          >
                            Add Inventory
                          </NavLink>
                        )}
                    </li>
                    <li>
                      {(isAdmin ||
                        hasPermission(permissions, "inventory", "create")) && (
                          <NavLink
                            to="/inventory"
                            className="submenu-link"
                            onClick={handleSidebarClose}
                          >
                            Inventory
                          </NavLink>
                        )}
                    </li>
                  </ul>
                </li>
              )}
              {(isAdmin || hasTablePermission("agencies")) && (
                <li>
                  <a href="" className="has-arrow" onClick={toggleSubMenu}>
                    <i className="fa fa-id-badge"></i>
                    <span>Agency</span>
                  </a>
                  <ul className="submenu">
                    <li>
                      {(isAdmin ||
                        hasPermission(permissions, "agencies", "read")) && (
                          <NavLink
                            to="/agency"
                            className="submenu-link"
                            onClick={handleSidebarClose}
                          >
                            All Agencies
                          </NavLink>
                        )}
                    </li>
                    <li>
                      {(isAdmin ||
                        hasPermission(permissions, "agencies", "create")) && (
                          <NavLink
                            to="/add-agency"
                            className="submenu-link"
                            onClick={handleSidebarClose}
                          >
                            Add Agency
                          </NavLink>
                        )}
                    </li>
                  </ul>
                </li>
              )}
              {(isAdmin || hasTablePermission("due_income")) && (
                <li>
                  <a href="" className="has-arrow" onClick={toggleSubMenu}>
                    <i className="fa fa-id-badge"></i>
                    <span>Due Bills</span>
                  </a>
                  <ul className="submenu">
                    <li>
                      {(isAdmin ||
                        hasPermission(permissions, "due_income", "read")) && (
                          <NavLink
                            to="/due-bills"
                            className="submenu-link"
                            onClick={handleSidebarClose}
                          >
                            Due Bills
                          </NavLink>
                        )}
                    </li>
                    <li>
                      {(isAdmin ||
                        hasPermission(permissions, "due_income", "create")) && (
                          <NavLink
                            to="/add-due-bill"
                            className="submenu-link"
                            onClick={handleSidebarClose}
                          >
                            Add Due Bill
                          </NavLink>
                        )}
                    </li>
                  </ul>
                </li>
              )}
              {isAdmin && (
                // || hasSettingsPermission()
                <li>
                  <a href="" className="has-arrow" onClick={toggleSubMenu}>
                    <i className="fa fa-cogs"></i>
                    <span>Settings</span>
                  </a>
                  <ul className="submenu">

                    {(isAdmin || hasTablePermission("roles")) && (
                      <li>
                        <a
                          href=""
                          className="has-arrow"
                          onClick={toggleSubMenu1}
                        >
                          <i className="fa fa-user-md"></i>
                          <span>Roles</span>
                        </a>
                        <ul className="submenu1">
                          <li>
                            {(isAdmin ||
                              hasPermission(permissions, "roles", "read")) && (
                                <NavLink
                                  to="/roles"
                                  className="submenu-link"
                                  onClick={handleSidebarClose}
                                >
                                  All Roles
                                </NavLink>
                              )}
                          </li>
                          <li>
                            {(isAdmin ||
                              hasPermission(
                                permissions,
                                "roles",
                                "create"
                              )) && (
                                <NavLink
                                  to="/addroles"
                                  className="submenu-link"
                                  onClick={handleSidebarClose}
                                >
                                  Add Roles
                                </NavLink>
                              )}
                          </li>
                        </ul>
                      </li>
                    )}
                    {(isAdmin || hasTablePermission("currencies")) && (
                      <li>
                        <a
                          href=""
                          className="has-arrow"
                          onClick={toggleSubMenu1}
                        >
                          <i className="fa fa-money"></i>
                          <span>Currency</span>
                        </a>
                        <ul className="submenu1">
                          <li>
                            {(isAdmin ||
                              hasPermission(permissions, "currencies", "read")) && (
                                <NavLink
                                  to="/currency-list"
                                  className="submenu-link"
                                  onClick={handleSidebarClose}
                                >
                                  Currency List
                                </NavLink>
                              )}
                          </li>
                          <li>
                            {(isAdmin ||
                              hasPermission(
                                permissions,
                                "currencies",
                                "create"
                              )) && (
                                <NavLink
                                  to="/add-currency"
                                  className="submenu-link"
                                  onClick={handleSidebarClose}
                                >
                                  Add Currency
                                </NavLink>
                              )}
                          </li>
                        </ul>
                      </li>
                    )}
                    {(isAdmin || hasTablePermission("role_permissions")) && (
                      <li>
                        <NavLink
                          to="/permissions"
                          className="submenu-link"
                          onClick={handleSidebarClose}
                        >
                          <i className="fa fa-check-square-o"></i>
                          Permissions
                        </NavLink>
                      </li>
                    )}
                    {(isAdmin || hasTablePermission("expense_types")) && (
                      <li>
                        <a
                          href=""
                          className="has-arrow"
                          onClick={toggleSubMenu1}
                        >
                          <i className="fa fa-minus-circle"></i>
                          <span>Expense Types</span>
                        </a>
                        <ul className="submenu1">
                          <li>
                            {(isAdmin ||
                              hasPermission(
                                permissions,
                                "expense_types",
                                "read"
                              )) && (
                                <NavLink
                                  to="/allexpensetype"
                                  className="submenu-link"
                                  onClick={handleSidebarClose}
                                >
                                  All Exp Types
                                </NavLink>
                              )}
                          </li>
                          <li>
                            {(isAdmin ||
                              hasPermission(
                                permissions,
                                "expense_types",
                                "create"
                              )) && (
                                <NavLink
                                  to="/addexpensetype"
                                  className="submenu-link"
                                  onClick={handleSidebarClose}
                                >
                                  Add Exp Type
                                </NavLink>
                              )}
                          </li>
                        </ul>
                      </li>
                    )}
                    {(isAdmin || hasTablePermission("income_types")) && (
                      <li>
                        <a
                          href=""
                          className="has-arrow"
                          onClick={toggleSubMenu1}
                        >
                          <i className="fa fa-plus-circle"></i>
                          <span>Income Types</span>
                        </a>
                        <ul className="submenu1">
                          <li>
                            {(isAdmin ||
                              hasPermission(
                                permissions,
                                "income_types",
                                "read"
                              )) && (
                                <NavLink
                                  to="/allincometype"
                                  className="submenu-link"
                                  onClick={handleSidebarClose}
                                >
                                  All Income Types
                                </NavLink>
                              )}
                          </li>
                          <li>
                            {(isAdmin ||
                              hasPermission(
                                permissions,
                                "income_types",
                                "create"
                              )) && (
                                <NavLink
                                  to="/addincometype"
                                  className="submenu-link"
                                  onClick={handleSidebarClose}
                                >
                                  Add Income Type
                                </NavLink>
                              )}
                          </li>
                        </ul>
                      </li>
                    )}
                  </ul>
                </li>
              )}
            </ul>
          </nav>
        </div>
      ) : (
        <div className="toggle_sidebar_button">
          <button
            type="button"
            className="btn-toggle-offcanvas show_button"
            onClick={toggleSidebar}
          >
            <i className="fa fa-align-left"></i>
          </button>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
