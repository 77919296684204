import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import Navbar from "../Navbar";
import { Modal, ModalHeader } from "reactstrap";
import { Apiurl } from "../../../services/apiRoute";
import {
  deleteMethod,
  getMethod,
  putMethod,
} from "../../../services/base_service";

const CurrencyList = () => {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  const [currencies, setCurrencies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState(false);

  // get currency list
  const getCurrencies = async () => {
    try {
      setIsLoading(true);
      const response = await getMethod(`${Apiurl.currency}/list`);
      setCurrencies(response.data.currency);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  // delete currency
  const handleDelete = async (id) => {
    try {
      setIsLoading(true);
      const response = await deleteMethod(`${Apiurl.currency}/${id}`);
      getCurrencies();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  // edit tpc
  const setEditModal = (id) => {
    setModal(true);
    const selected = currencies.find((item) => item.id === id);
    setValue("id", id);
    setValue("name", selected.name);
  };

  const handleUpdateCurrency = async (data) => {
    try {
      setIsLoading(true);
      const response = await putMethod(`${Apiurl.currency}/update`, data);
      setModal(false);
      getCurrencies();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCurrencies();
  }, []);

  return (
    <div>
      <Navbar />
      <Modal isOpen={modal} toggle={() => setModal(!modal)}>
        <ModalHeader toggle={() => setModal(!modal)}>
          <div className="container-fluid">
            <div className="block-header">
              <div className="row clearfix">
                <div className="col-lg-4 col-md-12 col-sm-12">
                  <h1 style={{ width: "270px" }}>Update Currency</h1>
                </div>
              </div>
            </div>
            <div className="row clearfix">
              <div className="col-sm-12">
                <div className="">
                  <div className="body">
                    <form onSubmit={handleSubmit(handleUpdateCurrency)}>
                      <div className="form-group row">
                        <label
                          className="col-sm-2 col-form-label"
                          style={{ marginRight: "50px" }}
                        >
                          Name
                          <span
                            className="text-danger"
                            style={{ margin: "4px" }}
                          >
                            *
                          </span>
                        </label>
                        <div className="col-md-6 col-sm-10">
                          <input
                            type="text"
                            {...register("name", {
                              required: "Name is Required.",
                            })}
                            className="form-control"
                            name="name"
                            placeholder="Enter Name"
                          />
                          {errors.name && (
                            <p style={{ color: "red", fontSize: "13px" }}>
                              {errors.name.message}
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="form-group row mt-1">
                        <label className="col-sm-2 col-form-label"></label>
                        <div className="col-md-6 col-sm-10">
                          <button
                            type="submit"
                            className="btn btn-success btn_bg_color"
                            style={{ marginRight: "20px" }}
                          >
                            Submit
                          </button>
                          <div
                            className="btn btn-secondary"
                            style={{ backgroundColor: "grey" }}
                            onClick={() => {
                              setModal(false);
                              reset();
                            }}
                          >
                            Cancel
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalHeader>
      </Modal>

      <div id="main-content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row clearfix">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <h1>Currencies</h1>
              </div>
              <div className="col-lg-8 col-md-12 col-sm-12 text-lg-right">
                <div className="d-flex align-items-center justify-content-lg-end mt-4 mt-lg-0 flex-wrap vivify pullUp delay-550">
                  <div className="mb-3 mb-xl-0">
                    <Link to="/add-currency">
                      <span className="btn btn-dark">Add New</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-sm-12">
              {isLoading ? (
                <span className="loader"></span>
              ) : (
                <>
                  <div className="table-responsive">
                    <table className="table table-hover table-custom spacing5">
                      <thead>
                        <tr>
                          <th>Sr.No</th>
                          <th>Name</th>
                          <th>Action</th>
                        </tr>
                      </thead>

                      <tbody>
                        {currencies.length > 0 ? (
                          currencies.map((list, i) => (
                            <React.Fragment key={i}>
                              <tr>
                                <td>#{i + 1}</td>
                                <td>{list.name}</td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn btn-default btn-sm edit-buton"
                                    title="Edit"
                                    onClick={() => setEditModal(list.id)}
                                  >
                                    <i className="fa fa-pencil text-success edit-button"></i>
                                  </button>

                                  <button
                                    type="button"
                                    className="btn btn-default btn-sm"
                                    title="Delete"
                                    onClick={() => handleDelete(list.id)}
                                  >
                                    <i className="fa fa-trash-o text-danger delete-button"></i>
                                  </button>
                                </td>
                              </tr>
                            </React.Fragment>
                          ))) : (
                          <tr>
                            <td style={{ borderTop: "none", textAlign: "center" }} colSpan={3}>No records found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {/* <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  /> */}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurrencyList;
