import React from 'react';
import Navbar from './Navbar';

const AddPayments = () => {
  return (
    <div>
      <Navbar />
      <div id="main-content">
        <div class="container-fluid">
          <div class="block-header">
            <div class="row clearfix">
              <div class="col-lg-4 col-md-12 col-sm-12">
                <h1>Hi, Welcomeback!</h1>
                <span>JustDo Add New Payments,</span>
              </div>

              <div class="col-lg-8 col-md-12 col-sm-12 text-lg-right">
                <div class="d-flex align-items-center justify-content-lg-end mt-4 mt-lg-0 flex-wrap vivify pullUp delay-550">
                  <div class="border-right pr-4 mr-4 mb-2 mb-xl-0 hidden-xs">
                    <p class="text-muted mb-1">Today's</p>
                    <h5 class="mb-0">$214</h5>
                  </div>
                  <div class="border-right pr-4 mr-4 mb-2 mb-xl-0">
                    <p class="text-muted mb-1">Week</p>
                    <h5 class="mb-0">$1206</h5>
                  </div>
                  <div class="pr-4 mb-2 mb-xl-0">
                    <p class="text-muted mb-1">Month</p>
                    <h5 class="mb-0">$2420</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row clearfix">
            <div class="col-sm-12">
              <div class="card">
                <div class="body">
                  <form>
                    <div class="form-group row">
                      <label class="col-sm-2 col-form-label">Patients Information</label>
                      <div class="col-md-6 col-sm-10">
                        <div class="form-group">
                          <input type="number" class="form-control" placeholder="Invoice Number" />
                        </div>
                        <div class="form-group">
                          <input type="text" class="form-control" placeholder="Patient Name" />
                        </div>
                        <select class="form-control show-tick">
                          <option value>- Doctor -</option>
                          <option>Dr. John Smith</option>
                          <option>Dr. Maryam Amiri</option>
                          <option>Dr. Hossein Shams </option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group row mt-5">
                      <label class="col-sm-2 col-form-label">Payment Information</label>
                      <div class="col-md-6 col-sm-10">
                        <div class="form-group">
                          <label>Full name (on the card)</label>
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text"><i class="fa fa-user"></i></span>
                            </div>
                            <input type="text" class="form-control" name="username" placeholder required />
                          </div>
                        </div>
                        <div class="form-group">
                          <label>Card number</label>
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text"><i class="fa fa-credit-card"></i></span>
                            </div>
                            <input type="text" class="form-control" name="cardNumber" placeholder />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-8">
                            <div class="form-group">
                              <label><span class="hidden-xs">Expiration</span> </label>
                              <div class="form-inline">
                                <select class="form-control" style={{ width: "45%" }}>
                                  <option>MM</option>
                                  <option>01 - Janiary</option>
                                  <option>02 - February</option>
                                  <option>03 - February</option>
                                </select>
                                <span style={{ width: "10%", textAlign: "center" }}> / </span>
                                <select class="form-control" style={{ width: "45%" }}>
                                  <option>YY</option>
                                  <option>2018</option>
                                  <option>2019</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-4">
                            <div class="form-group">
                              <label data-toggle="tooltip" title data-original-title="3 digits code on back side of the card">CVV <i class="fa fa-question-circle"></i></label>
                              <input class="form-control" required type="text" />
                            </div>
                          </div>
                        </div>
                        <button class="subscribe btn btn-primary btn-block" type="button"> Confirm </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddPayments;
