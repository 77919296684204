import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import { postMethod, getMethod } from "../../services/base_service";
import { Apiurl } from "../../services/apiRoute";
import "../../css/sidebar.css";


const Transfer = () => {
  const [users, setUsers] = useState([]);

  const [formValue, setFormValue] = useState({
    from: 0,
    to: 0,
    currency_id: 0,
    payment_type: "cash",
    amount: "",
    date: "",
    note: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({
    from: "",
    to: "",
    currency_id: "",
    payment_type: "",
    amount: "",
    date: "",
  });
  const [currencies, setCurrencies] = useState([])

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    let formIsValid = true;
    const newErrors = { ...errors };

    if (!formValue.from) {
      newErrors.from = "Sender name is required.";
      formIsValid = false;
    }
    if (!formValue.to) {
      newErrors.to = "Receiver name is required.";
      formIsValid = false;
    }

    if (!formValue.amount) {
      newErrors.amount = "Amount is required.";
      formIsValid = false;
    }
    if (!formValue.currency_id) {
      newErrors.currency_id = "Currency Type is required.";
      formIsValid = false;
    }
    if (!formValue.date) {
      newErrors.date = "Date is required.";
      formIsValid = false;
    }
    if (!formIsValid) {
      setErrors(newErrors);
      return;
    }

    try {
      const response = await postMethod(`${Apiurl.transfer}`, formValue);
      setFormValue({
        from: 0,
        to: 0,
        currency_id: 0,
        payment_type: "cash",
        amount: "",
        note: "",
        date:""
      });
    } catch (error) {
    }
  };

  const getUsers = async () => {
    await getMethod(`${Apiurl.filter_user_list}?checkForPermission=false`)
      .then((res) => {
        setUsers(res.data.data);
      })
      .catch((error) => {
      });
  };

  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const getCurrencies = async () => {
    await getMethod(`${Apiurl.currency}/list?checkForPermission=false`).then((data) => {
      setCurrencies(data.data.currency)
    }).catch((error) => {
    })
  }

  useEffect(() => {
    getCurrencies();
    getUsers();
  }, []);
  return (
    <div>
      <Navbar />
      <div id="main-content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row clearfix">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <h1>Transfer Amount</h1>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-sm-12">
              <div className="card">
                <div className="body">
                  <form onSubmit={handleEditSubmit}>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        From
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <select
                          name="from"
                          value={formValue.from}
                          onChange={handleChange}
                          className="form-control input-height"
                        >
                          <option value="">Select Sender...</option>
                          {users.map((user) => (
                            <option key={user.id} value={user.id}>
                              {user.name}
                            </option>
                          ))}
                        </select>
                        {errors.from && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.from}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        To
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <select
                          name="to"
                          value={formValue.to}
                          onChange={handleChange}
                          className="form-control input-height"
                        >
                          <option value="">Select Receiver...</option>
                          {users.map((user) => (
                            <option key={user.id} value={user.id}>
                              {user.name}
                            </option>
                          ))}
                        </select>
                        {errors.to && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.to}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Currency Type
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <select
                          name="currency_id"
                          value={formValue.currency_id}
                          onChange={handleChange}
                          className="form-control input-height"
                        >
                          <option value="">Select Currency Type...</option>
                          {currencies.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                        {errors.currency_id && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.currency_id}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Amount
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <input
                          type="text"
                          name="amount"
                          value={formValue.amount}
                          onChange={handleChange}
                          className="form-control"
                          placeholder="Enter Amount to Transfer"
                        />
                        {errors.amount && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.amount}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Date
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <input
                          type="date"
                          name="date"
                          value={formValue.date}
                          onChange={handleChange}
                          className="form-control"
                          placeholder="Enter Date"
                        />
                        {errors.date && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.date}
                          </p>
                        )}
                      </div>
                    </div>
                    
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Note
                        {formValue.payment_type === "cheque" && (
                          <span
                            className="text-danger"
                            style={{ margin: "4px" }}
                          >
                            *
                          </span>
                        )}
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <input
                          type="text"
                          value={formValue.note}
                          name="note"
                          onChange={handleChange}
                          className="form-control"
                          placeholder="Enter Note"
                        />
                        {errors.note && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.note}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label"></label>
                      <div className="col-md-6 col-sm-10">
                        <button
                          type="submit"
                          className="btn btn-success btn_bg_color"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Transfer;
