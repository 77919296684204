// import Icon fron '../images/icon.svg'
import logo_image from "../../images/icon.svg";
import User_image from "../../images/user.png";

export const Header = {
  Logo_image: logo_image,
  User_image: User_image,
  Main_title: "Hisab",
  Sub_title: "Hisab",
  Greetings: "Welcome",
};
