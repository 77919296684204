export const Apiurl = {
  // routePath: "http://192.168.1.27:3008/api/v1",
  // routePath: "http://localhost:7000/api/v1",
  routePath: "https://accountapi.fxgava.com/api/v1",
  // routePath: "https://accountapi.heartyrobo.com/api/v1",

  login: "/user/login",
  register: "/user/role",
  user: "/user",
  role: "/role",
  permission: "/rolePermit",
  transaction: "/transaction",
  expense_type: "/expense-type",
  income_type: "/income-type",
  expense: "/expense",
  income: "/income",
  collection: "/collection",
  transfer: "/transfer",
  policy: "/policy",
  rolePermit: "/rolePermit",
  agency: "/agency",
  inventory: "/inventory",
  dueIncome: "/due-income",
  currency: "/currency",
  currency_buy_sell: "/buy_sell_currency",
  findAccounts: "/terminal/find",
  deposit_withdrawals_list: "/deposit_withdrawals/list",
  deposit_withdrawals: "/deposit_withdrawals",
  credit_list: "/credit/list",
  credit_get_list: "/credit/get_list",
  credit_in_out: "/credit/in_out",
  generate_excel: "/generate/excel",
  mt5_usdt_add: "/mt5_usdt/add",
  mt5_usdt: "/mt5_usdt",
  mt5_usdt_list: "/mt5_usdt/list",
  user_list: "/user/user_list",
  filter_user_list: "/user/user_list/filter",
  user_transferList_excel: "/transfer/generat_excel",
  user_useCollection_excel: "/collection/generat_excel",
  buy_sell_currency_excel: "/buy_sell_currency/generate/excel",
  All_terminal_list: "/terminal/terminal_list",
};
