import React from "react";
import { Header } from "../utils/NameString";
import "../../css/sidebar.css";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();
  const handleLogout = async () => {
    await localStorage.clear();
    navigate("/login");
  };

  return (
    <div>
      <nav className="navbar navbar-fixed-top">
        <div className="container-fluid">
          <div className="navbar-left">
            <div className="navbar-btn">
              <img
                src={Header.Logo_image}
                alt="hospital Logo"
                className="img-fluid logo"
              />
              {/* <button type="button" className="btn-toggle-offcanvas">
                <i className="fa fa-align-left"></i>
              </button> */}
            </div>
          </div>
          <div className="navbar-right">
            <div id="navbar-menu">
              <ul className="nav navbar-nav">
                <li className="dropdown" style={{ cursor: "pointer" }}>
                  <div href="" className="dropdown-toggle icon-menu" style={{fontSize:"23px", color:"#19bfb0"}} data-toggle="dropdown">
                    <i className="fa fa-question-circle"></i>
                  </div>
                  <ul className="dropdown-menu feeds_widget mt-0 animation-li-delay">
                    <li className="header gradient mt-0 text-dark">Help - Shortcut keys</li>
                    <li>
                      <a>
                        <div className="mr-4"><i className="fa fa-circle text-info"></i></div>
                        <div className="feeds-body">
                          <h4 className="title text-success">Control + F1</h4>
                          <small>Go to Deposit/Withdraw</small>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a>
                        <div className="mr-4"><i className="fa fa-circle text-info"></i></div>
                        <div className="feeds-body">
                          <h4 className="title text-success">Control + F2</h4>
                          <small>Go to Credit In/Out</small>
                        </div>
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  <div className="icon-menu" data-toggle="tooltip" data-placement="bottom" title="Logout"
                    style={{ cursor: "pointer",fontSize:"21px", color:"red"}} onClick={() => handleLogout()}>
                    <i className="fa fa-power-off delete-button"></i>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
