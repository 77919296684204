import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form"
import Navbar from "../Navbar";
import { postMethod, getMethod } from "../../../services/base_service";
import { Apiurl } from "../../../services/apiRoute";
import "../../../css/sidebar.css"
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";

const CreditInOut = () => {

  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    setError,
    watch,
  } = useForm();

  const [users, setUsers] = useState([])
  const [inputTimeout, setInputTimeout] = useState(null);
  const [accNo, setAccNo] = useState("")


  const getCurrentDate = () => {
    const currentDate = new Date();
    const day = currentDate.getDate().toString().padStart(2, "0");
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const year = currentDate.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const needDeposit = watch("need_deposit")
  const creditOut = watch("transaction_type")

  const defaultDate = getCurrentDate();

  const getUsers = async () => {
    await getMethod(`${Apiurl.filter_user_list}?checkForPermission=false`)
      .then((res) => {
        setUsers(res.data.data);
      })
      .catch((error) => {
      });
  };

  const handleAccountCheck = (e) => {
    setError("account_no", { type: "custom", message: "" })
    if (inputTimeout) clearTimeout(inputTimeout);
    setInputTimeout(
      setTimeout(() => {
        setAccNo(e.target.value);
      }, 500)
    );
  }

  const delayedSearch = debounce(() => {
    findAccount();
  }, 500);

  const findAccount = async () => {
    if (accNo === undefined || accNo === "") {
      return;
    }
    await postMethod(`${Apiurl.findAccounts}/${accNo}`)
      .then((res) => {
        if (res.status === 404) {
          setValue("name", res.data?.account?.name);
          setError("name", { type: "custom", message: "" })
          setAccNo("")
        } else if (res.status === 403) {
          setValue("name", res.data.account?.name);
          setError("name", { type: "custom", message: "" })
          setAccNo("")
        }
      })
      .catch((error) => {
        console.log('error: ', error)
      });
  }


  const handleCreditInOut = async (data) => {
    const { transaction_type, need_deposit, ...payloadData } = data
    const URL = data.transaction_type === "credit_in" ? `/credit/in` : `/credit/out`

    await postMethod(URL, payloadData).then((data1) => {

      // if (data.need_deposit) {
      //   navigate(
      //     "/deposit-withdraw",
      //     {
      //       state: { formData: data }
      //     }
      //   )
      // }
      reset()
      setValue("transaction_type", "");
      setValue("user_id", "");
    }).catch((error) => {

    })
  }

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    delayedSearch()
  }, [accNo]);

  return (
    <div>
      <Navbar />
      <div id="main-content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row clearfix">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <h1>Credit In/Out</h1>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-sm-12">
              <div className="card">
                <div className="body">
                  <form onSubmit={handleSubmit(handleCreditInOut)}>

                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Transaction Type
                        <span className="text-danger">*</span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <select defaultValue=""
                          id="transaction_type"
                          name="transaction_type"
                          className="form-control"
                          {...register("transaction_type", {
                            required: "Transaction Type is Required.",
                          })}
                        >
                          <option value="" disabled >Select Transaction Type...</option>
                          <option value="credit_in">Credit In</option>
                          <option value="credit_out">Credit Out</option>
                        </select>
                        {errors.transaction_type && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.transaction_type.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        User
                        <span className="text-danger">*</span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <select defaultValue=""
                          id="user_id"
                          name="user_id"
                          className="form-control"
                          {...register("user_id", {
                            required: "User is Required.",
                          })}
                        >
                          <option value="" disabled >
                            Select User...
                          </option>
                          {users.map((item) => (
                            <option key={item.id} value={item.id}>{item.name}</option>
                          ))}
                        </select>
                        {errors.user_id && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.user_id.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Account Number
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <input
                          type="number"
                          {...register("account_no", {
                            required: "Account Number is Required.",
                          })}
                          className="form-control"
                          name="account_no"
                          id="inputEmail3"
                          placeholder="Enter Account Number"
                          onChange={handleAccountCheck}
                        />
                        {errors.account_no && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.account_no.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Name
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <input
                          type="text"
                          {...register("name", {
                            required: "Name is Required.",
                          })}
                          className="form-control"
                          name="name"
                          id="inputEmail3"
                          placeholder="Enter Name"
                        />
                        {errors.name && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.name.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        MT5 USDT
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <input
                          type="number"
                          {...register("mt5_usdt", {
                            required: "MT5 USDT is Required.",
                          })}
                          className="form-control"
                          name="mt5_usdt"
                          id="inputEmail3"
                          placeholder="Enter MT5 USDT Number"
                        />
                        {errors.mt5_usdt && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.mt5_usdt.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Date
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <input
                          type="date"
                          {...register("date", {
                            required: "Date is Required.",
                          })}
                          name="date"
                          className="form-control"
                          id="date"
                          // placeholder="Enter Date"
                          defaultValue={defaultDate}
                        />
                        {errors.date && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.date.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Note
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <input
                          type="text"
                          {...register("note")}
                          className="form-control"
                          name="note"
                          id="note"
                          placeholder="Enter Note"
                        />

                      </div>
                    </div>
                    {/* {creditOut &&
                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label">
                          Need To Deposit ?
                        </label>
                        <div className="col-md-6 col-sm-10">
                          <input
                            type="checkbox"
                            {...register("need_deposit")}
                            className="form-control"
                            name="need_deposit"
                            id="need_deposit"
                          />
                        </div>
                      </div>
                    } */}

                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label"></label>
                      <div className="col-md-6 col-sm-10 d-flex">
                        <button
                          type="submit"
                          className="btn btn-success btn_bg_color"
                        >
                          {/* {needDeposit ? "Submit & Add Deposit" : "Submit"} */}
                          Submit
                        </button>
                        {/* <button
                          type="submit"
                          className="btn btn-success btn_bg_color ml-5"
                        >
                          Submit & Add Deposit
                        </button> */}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreditInOut;
