import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import { getMethod } from "../../services/base_service";
import { Apiurl } from "../../services/apiRoute";

const Dashboard = () => {

  const [currencyData, setCurrencyData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getDashboard = async () => {
    setIsLoading(true);
    await getMethod(`${Apiurl.user}/dashboard`)
      .then((res) => {
        setCurrencyData(res.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getDashboard();
  }, []);

  return (
    <div>
      <Navbar />
      <div id="main-content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row clearfix">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <h1>Dashboard</h1>
              </div>
            </div>
          </div>

          {isLoading ? <span className="loader"></span> :
            <>
              <div className="row clearfix row-deck">
                {currencyData.map((currency, index) => (
                  <div className="col-xl-3 col-lg-3 col-md-6" key={index}>
                    <div className="card shadow">
                      <div className="body">
                        <div className="font-15">Total Income <strong>({currency.currency_names})</strong></div>
                        <div className="pt-1 m-0 h3 income-color">
                          {currency.totalIncome !== null ? currency.totalIncome.toFixed(2) : 0}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                {currencyData.length === 0 &&
                  <div className="col-xl-3 col-lg-3 col-md-6">
                    <div className="card shadow">
                      <div className="body">
                        <div className="font-15">Total Income</div>
                        <div className="pt-1 m-0 h3 income-color">0</div>
                      </div>
                    </div>
                  </div>
                }
              </div>
              <hr style={{
                background: "#82b440",
                height: "1px",
                border: "none",
                margin: "0px 0px 10px 0px",
              }}
              />
              <div className="row clearfix row-deck">
                {currencyData.map((currency, index) => (
                  <div className="col-xl-3 col-lg-3 col-md-6" key={index}>
                    <div className="card shadow">
                      <div className="body">
                        <div className="font-15">Total Expense  <strong>({currency.currency_names})</strong></div>
                        <div className="pt-1 m-0 h3 expense-color">
                          {currency.totalExpense !== null ? currency.totalExpense.toFixed(2) : 0}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                {currencyData.length === 0 &&
                  <div className="col-xl-3 col-lg-3 col-md-6">
                    <div className="card shadow">
                      <div className="body">
                        <div className="font-15">Total Expense</div>
                        <div className="pt-1 m-0 h3 expense-color">0</div>
                      </div>
                    </div>
                  </div>
                }
              </div>
              <hr style={{
                background: "#82b440",
                height: "1px",
                border: "none",
                margin: "0px 0px 10px 0px",
              }}

              />
              <div className="row clearfix row-deck">
                {currencyData.map((currency, index) => (
                  <div className="col-xl-3 col-lg-3 col-md-6" key={index}>
                    <div className="card shadow">
                      <div className="body">
                        <div className="font-15">Net Amount  <strong>({currency.currency_names})</strong></div>
                        <div className="pt-1 m-0 h3 netanount-color">
                          {currency.totalAmount !== null ? currency.totalAmount.toFixed(2) : 0}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                {currencyData.length === 0 &&
                  <div className="col-xl-3 col-lg-3 col-md-6">
                    <div className="card shadow">
                      <div className="body">
                        <div className="font-15">Net Amount</div>
                        <div className="pt-1 m-0 h3 netanount-color">0</div>
                      </div>
                    </div>
                  </div>
                }
              </div>
              <hr style={{
                background: "#82b440",
                height: "1px",
                border: "none",
                margin: "0px 0px 10px 0px",
              }}
              />
            </>}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
