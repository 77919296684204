import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { postMethod } from "../../../services/base_service";
import { Apiurl } from "../../../services/apiRoute";
import Navbar from "../Navbar";

const AddIncomeType = () => {
  const [incomeType, setIncomeType] = useState("");
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (incomeType === "") {
        setErrors({ income_type: "Income Type Cannot be empty" });
      }
      if (incomeType) {
        const response = await postMethod(Apiurl.income_type, {
          type: incomeType,
        });
        setIncomeType("");
        setErrors({});
        // navigate("/allincometype");
      }
    } catch (error) {
      if (error.error === "Record Already Exists") {
        setErrors({ form: "Income type already exists." });
      } else
        setErrors({
          form: "An error occurred while adding the user. Please try again.",
        });
    }
  };
  return (
    <div>
      <Navbar />
      <div id="main-content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row clearfix">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <h1>Add New Income Types</h1>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-sm-12">
              <div className="card">
                <div className="body">
                  <form onSubmit={handleSubmit}>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Income Types
                        <span
                          className="text-danger"
                          style={{ margin: "-3px" }}
                        >
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <input
                          type="text"
                          value={incomeType}
                          onChange={(e) => setIncomeType(e.target.value)}
                          className="form-control"
                          placeholder="Enter income type"
                        />
                        {errors.income_type && (
                          <p style={{ color: "red" }}>{errors.income_type}</p>
                        )}
                      </div>
                    </div>
                    {errors.form && (
                      <p style={{ color: "red" }}>{errors.form}</p>
                    )}
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label"></label>
                      <div className="col-md-6 col-sm-10">
                        <button
                          type="submit"
                          className="btn btn-primary btn_bg_color"
                        >
                          Submit
                        </button>
                        {/* <button type="submit" className="btn btn-default" style={{backgroundColor:"grey"}}>Cancel</button> */}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddIncomeType;
