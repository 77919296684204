import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import { getMethod, postMethod } from "../../../services/base_service";
import { Apiurl } from "../../../services/apiRoute";

const Permissions = () => {
  const [roles, setRoles] = useState([]);
  const [roleId, setRoleId] = useState(1);
  const [permissions, setPermissions] = useState([]);
  const [updatedPermissions, setUpdatedPermissions] = useState([]);

  const tableNames = [
    { key: "Collection", value: "collections" },
    { key: "Expense", value: "expenses" },
    { key: "Income", value: "incomes" },
    { key: "Transfer", value: "transfer" },
    { key: "Users", value: "users" },
    { key: "Buy/Sell", value: "buy_sell_currencies" },
    { key: "Credit In/Out", value: "credit_in_out" },
    // { key: "Currency", value: "currencies" },
    { key: "Deposit/Withdrawl", value: "deposit_withdrawals" },
    // { key: "Terminals", value: "terminals" },
    { key: "mt5usdt_transaction", value: "mt5usdt_transaction" },
    { key: "inventory", value: "inventory" },
    { key: "agencies", value: "agencies" },
    { key: "due_income", value: "due_income" },
  ];

  const getRoles = async () => {
    await getMethod(Apiurl.role, {
      page: 1,
      pageSize: 1000,
      search: "",
    })
      .then((res) => {
        setRoles(res.data.data);
      })
      .catch((error) => {
      });
  };

  const getPermissions = async () => {
    try {
      const response = await getMethod(
        `${Apiurl.permission}/role?role_id=${roleId}`
      );
      setPermissions(response.data.data);
    } catch (error) {
    }
  };

  const handleCheckboxChange = (tableName, action, isChecked) => {
    const existingPermissionIndex = permissions.findIndex(
      (p) => p.table_name === tableName
    );

    if (existingPermissionIndex !== -1) {
      // Update existing permission
      const updatedPermissionsList = [...permissions];
      updatedPermissionsList[existingPermissionIndex] = {
        ...permissions[existingPermissionIndex],
        [action]: isChecked,
      };
      setUpdatedPermissions(updatedPermissionsList);
      setPermissions(updatedPermissionsList);
    } else {
      // Add new permission
      const newPermission = {
        table_name: tableName,
        [action]: isChecked,
      };
      setUpdatedPermissions([...updatedPermissions, newPermission]);
      setPermissions([...permissions, newPermission]);
    }
  };
  const assignPermissions = async () => {
    try {
      const permissions = await updatedPermissions.map(
        ({ id, createdAt, updatedAt, deletedAt, role_id, ...rest }) => rest
      );
      const response = await postMethod(Apiurl.permission, {
        role_id: roleId,
        permissions: permissions,
      });

      getPermissions();
    } catch (error) {
    }
  };

  useEffect(() => {
    getRoles();
  }, []);

  useEffect(() => {
    getPermissions();
  }, [roleId]);

  return (
    <div>
      <Navbar />
      <div id="main-content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row clearfix">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <h1>Permissions</h1>
              </div>
              <div className="col-lg-8 col-md-12 col-sm-12 text-lg-right">
                <div className="d-flex align-items-center justify-content-lg-end mt-4 mt-lg-0 flex-wrap vivify pullUp delay-550">
                  <div className="mb-3 mb-xl-0">
                    <span className="btn btn-dark" onClick={assignPermissions}>
                      Save Permissions
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-sm-12">
              <div className="card">
                <div className="body">
                    <div className="input-group">
                      <select
                        value={roleId}
                        name="hospitalId"
                        onChange={(e) => setRoleId(e.target.value)}
                        className="form-control input-height"
                        style={{cursor: 'pointer'}}
                      >
                        <option value="">Select Role...</option>
                        {roles &&
                          roles.map((role, index) => (
                            <option key={index} value={role.id}>
                              {role.role}
                            </option>
                          ))}
                      </select>
                    </div>
                </div>
              </div>

              <>
                <div className="table-responsive">
                  <table className="table table-hover table-custom spacing5">
                    <thead>
                      <tr>
                        <th>Table Name</th>
                        <th>Create</th>
                        <th>Read</th>
                        <th>Update</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableNames.map((table, index) => (
                        <tr key={index}>
                          <td>{table.key}</td>
                          <td>
                            <input
                              type="checkbox"
                              checked={(permissions ?? []).some(
                                (p) => p.table_name === table.value && p.create
                              )}
                              onChange={(e) =>
                                handleCheckboxChange(
                                  table.value,
                                  "create",
                                  e.target.checked
                                )
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="checkbox"
                              checked={(permissions ?? []).some(
                                (p) => p.table_name === table.value && p.read
                              )}
                              onChange={(e) =>
                                handleCheckboxChange(
                                  table.value,
                                  "read",
                                  e.target.checked
                                )
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="checkbox"
                              checked={(permissions ?? []).some(
                                (p) => p.table_name === table.value && p.edit
                              )}
                              onChange={(e) =>
                                handleCheckboxChange(
                                  table.value,
                                  "edit",
                                  e.target.checked
                                )
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="checkbox"
                              checked={(permissions ?? []).some(
                                (p) => p.table_name === table.value && p.delete
                              )}
                              onChange={(e) =>
                                handleCheckboxChange(
                                  table.value,
                                  "delete",
                                  e.target.checked
                                )
                              }
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Permissions;
