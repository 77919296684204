import React from "react";

const Pagination = ({ currentPage, totalPages, onPageChange, itemPerPage, totalRecords }) => {
  // const pageNumbers = [...Array(totalPages).keys()].map((num) => num + 1);

  let pageNumbers = [];
  if (totalPages <= 7) {
    pageNumbers = [...Array(totalPages).keys()].map((num) => num + 1);
  } else if (currentPage <= 1) {
    pageNumbers = [1, 2, '...', totalPages - 1, totalPages];
  } else if (currentPage >= totalPages - 1) {
    pageNumbers = [1, "...", totalPages - 2, totalPages - 1, totalPages];
  } else if (currentPage === 2) {
    pageNumbers = [1, currentPage, currentPage + 1, '...', totalPages];
  } else {
    pageNumbers = [1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages];
  }


  return (
    <div>
      {(totalRecords !== null && totalRecords !== undefined) &&
        <div>
          <p className="mt-2 mb-2">Showing <span className="font-weight-bold">{((currentPage - 1) * itemPerPage + 1)} to {Math.min(currentPage * itemPerPage, totalRecords)}</span>{" "}
           records from <span className="font-weight-bold">{totalRecords}</span>{" "}of total Records</p>
        </div>
      }
      <nav aria-label="Page navigation example">
        <ul className="pagination">
          <li className={`page-item ${currentPage === 1 && "disabled"}`}>
            <button
              className="page-link"
              onClick={() => onPageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </button>
          </li>
          {pageNumbers.map((number, i) => (
            <li
              key={i}
              className={`page-item ${currentPage === number && "active"}`}
            >
              <button className="page-link" onClick={() => onPageChange(number)} disabled={number === "..."}>
                {number}
              </button>
            </li>
          ))}
          <li className={`page-item ${currentPage === totalPages && "disabled"}`}>
            <button
              className="page-link"
              onClick={() => onPageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Pagination;
