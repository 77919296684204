import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../Navbar";
import Pagination from "../../../services/Pagination";
import debounce from "lodash/debounce";
import { deleteMethod, getMethod, postMethod, putMethod } from "../../../services/base_service";
import { Apiurl } from "../../../services/apiRoute";
import { useForm } from "react-hook-form";
import { showConfirmationDialog } from "../../../services/alert_service";
import { Modal, ModalHeader } from "reactstrap";
import '../../../css/sidebar.css';

const CreditInOutList = () => {
  const [tableData, setTableData] = useState([]);
  const [droplist, setDropList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  // const [searchQuery, setSearchQuery] = useState("");
  // const [searcUser, setUserSearch] = useState("");
  const [selectedUser, setSelectedUser] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);

  const [inputTimeout, setInputTimeout] = useState(null);
  // const [searchComp, setSearchComp] = useState("");
  const [openDropdowns, setOpenDropdowns] = useState({});
  const [users, setUsers] = useState([])
  const [accNo, setAccNo] = useState("")
  const [modal, setModal] = useState(false);
  const [selected, setSelected] = useState({});

  const itemPerPage = 8

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    setError,
  } = useForm();

  const permissions = JSON.parse(localStorage.getItem("permissions"));
  const rolePermissions = permissions.find(
    (item) => item.table_name === "credit_in_out"
  );
  const isAdmin = localStorage.getItem("isAdmin") === "true";

  const getCreditList = async () => {
    try {
      setIsLoading(true);
      const response = await getMethod(`/credit/list`);
      setTableData(response.data.amount);
      // setTotalPages(response.data.total_page); 
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getUsers = async () => {
    await getMethod(`${Apiurl.filter_user_list}`)
      .then((res) => {
        // console.log('res>>>>: ', res.data.data)
        // const filtreredUsers = res.data.data.filter((user) => user.role.role !== "MANAGER (STAFF)")
        // console.log('filtreredUsers: ', filtreredUsers)
        setUsers(res.data.data);
      })
      .catch((error) => {
      });
  };

  useEffect(() => {
    getCreditList();
    getUsers()
  }, [])

  const handleRowClick = async (userId) => {

    setSelectedUserId(userId)
    setCurrentPage(1)
    setOpenDropdowns(prevState => ({ [userId]: !prevState[userId] }));

    if (openDropdowns[userId]) {
      return;
    }
    try {
      setIsLoading(true);
      getDropList(userId)
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }
  const isDropdownOpen = (userId) => {
    return openDropdowns[userId];
  }
  // setSelectedUser([user]);
  if (selectedUser) {
    setSelectedUser(false);
  }

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };


  useEffect(() => {
    delayedSearchAccount()
  }, [accNo]);

  // const handleSearch = (search) => {
  //   setSearchComp(search);
  //   if (inputTimeout) clearTimeout(inputTimeout);
  //   setInputTimeout(
  //     setTimeout(() => {
  //       // setSearchQuery(search);
  //       setCurrentPage(1);
  //     }, 500)
  //   );
  // };

  const handleAccount = (e) => {
    setError("account_no", { type: "custom", message: "" })
    if (inputTimeout) clearTimeout(inputTimeout);
    setInputTimeout(
      setTimeout(() => {
        setAccNo(e.target.value);
      }, 500)
    );
  }

  const delayedSearchAccount = debounce(() => {
    findAccount();
  }, 500);

  const findAccount = async () => {
    if (accNo === undefined || accNo === "") {
      return;
    }
    await postMethod(`${Apiurl.findAccounts}/${accNo}`)
      .then((res) => {
        if (res.status === 404) {
          setValue("name", res.data?.account?.name);
          setError("name", { type: "custom", message: "" })
          setAccNo("")
        } else if (res.status === 403) {
          setValue("name", res.data.account?.name);
          setError("name", { type: "custom", message: "" })
          setAccNo("")
        }
      })
      .catch((error) => {
        console.log('error: ', error)
      });
  }

  const setEditModal = (id) => {

    setError("transaction_type", { type: "custom", message: "" })
    setError("account_no", { type: "custom", message: "" })
    setError("name", { type: "custom", message: "" })
    setError("user_id", { type: "custom", message: "" })
    setError("mt5_usdt", { type: "custom", message: "" })
    setError("date", { type: "custom", message: "" })

    const selected1 = droplist.find((item) => item.id === id);
    setSelected(selected1.id);
    setModal(true);

    setValue('transaction_type', selected1.type === "Cr" ? "credit_in" : "credit_out");
    setValue('account_no', selected1.account_no);
    setValue('name', selected1.terminal.name);
    setValue('user_id', selected1.user_id);
    setValue('mt5_usdt', selected1.mt5_usdt);
    setValue('date', selected1.date);
    setValue('note', selected1.note);
  };

  const handleEditSubmit = async (data) => {
    // data.account_no = parseInt(data.account_no);
    // data.user_id = parseInt(data.user_id)
    data.isCreditInOut = data.transaction_type === 'credit_in' ? 1 : 0;

    delete data['transaction_type'];

    await putMethod(`${Apiurl.credit_in_out}/${selected}`, data)
      .then((res) => {
        reset()
        setModal(false)
        // getCreditList()
        getDropList()
      })
      .catch((error) => {
        // setModal(false)
        console.log('error: ', error)
      });
  }

  const handleDelete = async (id) => {
    try {
      const confirmation = await showConfirmationDialog(
        "Are you sure you want to delete ?",
        "",
        "warning"
      );
      if (confirmation) {
        await deleteMethod(`${Apiurl.credit_in_out}/${id}`)
          .then((res) => {
            getDropList()
          })
          .catch((error) => {
          });
      }
    } catch (error) {
    }
  };
  const getDropList = async (userId) => {
    try {
      const response = await getMethod(`${Apiurl.credit_list}/${userId || selectedUserId}?page=${currentPage}&limit=${itemPerPage}&search=`)
      setDropList(response.data.data);
      setTotalPages(response.data.total_page);
    } catch (error) {

    }
  }
  useEffect(() => {
    getDropList()
  }, [currentPage])

  return (
    <div>
      <Navbar />
      <Modal isOpen={modal} toggle={() => setModal(!modal)}>
        <ModalHeader toggle={() => setModal(!modal)} className="d-block">
          <div className="container-fluid">
            <div className="block-header">
              <div className="row clearfix">
                <div className="col-lg-4 col-md-12 col-sm-12">
                  <h1 style={{ width: "270px" }}>Update Credit In/Out</h1>
                </div>
              </div>
            </div>
            <div className="row clearfix">
              <div className="col-sm-12">
                <div className="">
                  <div className="body">
                    <form onSubmit={handleSubmit(handleEditSubmit)}>

                      <div className="form-group row justify-content-between align-items-center">
                        <label
                          className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 col-form-label"
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          Transaction Type
                          <span
                            className="text-danger"
                            style={{ margin: "4px" }}
                          >
                            *
                          </span>
                        </label>
                        <select
                          id="transaction_type"
                          name="transaction_type"
                          {...register("transaction_type", {
                            required: "Transaction Type is Required.",
                          })}
                          className="form-control input-height col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12"
                          style={{ marginLeft: "8px" }}
                        >
                          <option value="" disabled >Select Transaction Type...</option>
                          <option value="credit_in">Credit In</option>
                          <option value="credit_out">Credit Out</option>
                        </select>
                      </div>
                      {errors.transaction_type && (
                        <p className="useredit_err">{errors.transaction_type.message}</p>
                      )}

                      <div className="form-group row justify-content-between align-items-center">
                        <label
                          className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 col-form-label"
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          Fund User
                          <span className="text-danger" style={{ margin: "4px" }}>*</span>
                        </label>
                        <select
                          id="user_id"
                          name="user_id"
                          {...register("user_id", {
                            required: "User is Required.",
                          })}
                          className="form-control input-height col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12"
                          style={{ marginLeft: "8px" }}
                        >
                          <option value="">Select User...</option>
                          {users.map((user, i) => (
                            <option key={i} value={user.id}>
                              {user.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      {errors.user_id && (
                        <p className="useredit_err">{errors.user_id.message}</p>
                      )}

                      <div className="form-group row justify-content-between align-items-center">
                        <label
                          className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 col-form-label"
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          Account Number
                          <span
                            className="text-danger"
                            style={{ margin: "4px" }}
                          >
                            *
                          </span>
                        </label>
                        <input
                          type="number"
                          {...register("account_no", {
                            required: "Account Number is Required.",
                          })}
                          onWheel={(e) => e.target.blur()}
                          name="account_no"
                          id="account_no"
                          placeholder="Enter Account Number"
                          onChange={handleAccount}
                          className="form-control input-height col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12"
                          style={{ marginLeft: "8px" }}
                        >
                        </input>
                      </div>
                      {errors.account_no && (
                        <p className="useredit_err">{errors.account_no.message}</p>
                      )}


                      <div className="form-group row justify-content-between align-items-center">
                        <label
                          className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 col-form-label"
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          Name
                          <span className="text-danger" style={{ margin: "4px" }}>*</span>
                        </label>
                        <input
                          type="text"
                          {...register("name", {
                            required: "Name is Required.",
                          })}
                          onWheel={(e) => e.target.blur()}
                          name="name"
                          id="name"
                          placeholder="Enter Name"
                          className="form-control input-height col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12"
                          style={{ marginLeft: "8px" }}
                        >
                        </input>
                      </div>
                      {errors.name && (
                        <p className="useredit_err">{errors.name.message}</p>
                      )}



                      <div className="form-group row justify-content-between align-items-center">
                        <label
                          className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 col-form-label"
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          MT5 USDT
                          <span
                            className="text-danger"
                            style={{ margin: "4px" }}
                          >
                            *
                          </span>
                        </label>
                        <input
                          type="number"
                          {...register("mt5_usdt", {
                            required: "MT5 USDT is Required.",
                          })}
                          onWheel={(e) => e.target.blur()}
                          name="mt5_usdt"
                          id="mt5_usdt"
                          placeholder="Enter MT5 USDT Number"
                          className="form-control input-height col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12"
                          style={{ marginLeft: "8px" }}
                        >
                        </input>
                      </div>
                      {errors.mt5_usdt && (
                        <p className="useredit_err">{errors.mt5_usdt.message}</p>
                      )}

                      <div className="form-group row justify-content-between align-items-center">
                        <label
                          className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 col-form-label"
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          Date
                          <span
                            className="text-danger"
                            style={{ margin: "4px" }}
                          >
                            *
                          </span>
                        </label>
                        <input
                          type="date"
                          {...register("date", {
                            required: "Date is Required.",
                          })}
                          name="date"
                          id="date"
                          // defaultValue={defaultDate}
                          className="form-control input-height col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12"
                          style={{ marginLeft: "8px" }}
                        >
                        </input>
                      </div>
                      {errors.date && (
                        <p className="useredit_err">{errors.date.message}</p>
                      )}

                      <div className="form-group row justify-content-between align-items-center">
                        <label
                          className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 col-form-label"
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          Note
                          <span className="text-danger" style={{ margin: "4px" }}>*</span>
                        </label>
                        <input
                          type="text"
                          {...register("note")}
                          name="note"
                          id="note"
                          placeholder="Enter Note"
                          className="form-control input-height col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12"
                          style={{ marginLeft: "8px" }}
                        >
                        </input>
                      </div>

                      <div className="form-group row justify-content-between align-items-center">
                        <div className="col-12 text-center mt-2">
                          <button
                            type="submit"
                            className="btn btn-success btn_bg_color"
                            style={{ marginRight: "30px" }}
                          >
                            Submit
                          </button>
                          <div
                            className="btn btn-secondary"
                            style={{ backgroundColor: "grey" }}
                            onClick={() => {
                              setModal(false);
                            }}
                          >
                            Cancel
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalHeader>
      </Modal>
      <div id="main-content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row clearfix">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <h1>Credit In Out List</h1>
              </div>
              <div className="col-lg-8 col-md-12 col-sm-12 text-lg-right">
                <div className="d-flex align-items-center justify-content-lg-end mt-4 mt-lg-0 flex-wrap vivify pullUp delay-550">
                  <div className="mb-3 mb-xl-0">
                    <Link to="/credit-in-out">
                      <span className="btn btn-dark">Credit In/Out</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-sm-12">
              {/* <div className="card">
                <div className="body">

                  <div className="input-group">
                    <input
                      type="text"
                      name="search"

                      onChange={(e) => {
                        handleSearch(e.target.value);
                      }}
                      value={searchComp}
                      className="form-control"
                      placeholder="Search here..."
                    />
                    <div className="input-group-append">
                      <span className="input-group-text" id="search-mail">
                        <i className="fa fa-search text-info"></i>
                      </span>
                    </div>
                  </div>

                </div>
              </div> */}
              {isLoading ? (
                <span className="loader"></span>
              ) : (
                <>
                  <div className="table-responsive">
                    <table className="table table-hover table-custom spacing5">
                      <thead>
                        <tr>
                          <th>Sr.No</th>
                          <th>Name</th>
                          <th>Total Dr</th>
                          <th>Total Cr</th>
                          <th>Total Amount</th>
                        </tr>
                      </thead>

                      <tbody>
                        {tableData.length > 0 ? (
                          tableData.map((list, i) => (
                            <React.Fragment key={i}>
                              <tr>
                                <td style={{ cursor: "pointer" }}
                                  onClick={() => handleRowClick(list?.user_id)}>{i + 1}
                                  <i
                                    className={`fa ${isDropdownOpen(list?.user_id) ? "fa-caret-down" : "fa-caret-right"}`}
                                    aria-hidden="true"
                                    style={{ marginLeft: "8px" }}
                                  />
                                </td>
                                <td>{list.name}</td>
                                <td>{list.totalDr}</td>
                                <td>{list.totalCr}</td>
                                <td>{list.totalAmount}</td>

                              </tr>
                              {openDropdowns[list?.user_id] && droplist[0]?.user_id === list.user_id &&
                                <tr className="inlineTable">
                                  <td colSpan={8}>
                                    <div className="table-responsive">
                                      <table className="table table-hover table-custom spacing5 table-border-custom">
                                        <thead>
                                          <tr>
                                            <th>Sr No.</th>
                                            <th>Date</th>
                                            <th>Account No.</th>
                                            <th>Terminal name</th>
                                            <th>MT5 USDT</th>
                                            <th>Type</th>
                                            <th>User name</th>
                                            <th>Cr/Dr By</th>
                                            <th>Note</th>
                                            {(isAdmin ||
                                              rolePermissions?.edit ||
                                              rolePermissions?.delete) && <th>Action</th>}
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {openDropdowns[list?.user_id] && droplist[0]?.user_id === list.user_id && droplist?.map((user, index) => (
                                            <tr key={user?.id} className="rowColor">
                                              <td>#{(currentPage - 1) * itemPerPage + 1 + index}</td>
                                              <td>{user?.date}</td>
                                              <td>{user?.account_no}</td>
                                              <td>{user?.terminal?.name}</td>
                                              <td>{user?.mt5_usdt}</td>
                                              <td>{user?.type}</td>
                                              <td>{user?.users?.name}</td>
                                              <td>{user?.transaction_by_users?.name}</td>
                                              <td style={{ whiteSpace: "break-spaces" }}>{user?.note}</td>
                                              <td>
                                                {(isAdmin || rolePermissions?.edit) && (
                                                  <button
                                                    type="button"
                                                    className="btn btn-default btn-sm"
                                                    title="Edit"
                                                    onClick={() => setEditModal(user.id)}
                                                  >
                                                    <i className="fa fa-pencil text-success edit-button"></i>
                                                  </button>
                                                )}
                                                {(isAdmin || rolePermissions?.delete) && (
                                                  <button
                                                    type="button"
                                                    className="btn btn-default btn-sm"
                                                    title="Delete"
                                                    onClick={() => handleDelete(user.id)}
                                                  >
                                                    <i className="fa fa-trash-o text-danger delete-button"></i>
                                                  </button>
                                                )}
                                              </td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </table>
                                    </div>
                                    <Pagination
                                      currentPage={currentPage}
                                      totalPages={totalPages}
                                      onPageChange={handlePageChange}
                                    />
                                  </td>
                                </tr>
                              }
                            </React.Fragment>
                          ))) : (
                          <tr>
                            <td style={{ borderTop: "none", textAlign: "center" }} colSpan={5}>No records found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreditInOutList;
