import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import {
  getMethod,
  putMethod,
  deleteMethod,
} from "../../services/base_service";
import { Apiurl } from "../../services/apiRoute";
import { Modal, ModalHeader } from "reactstrap";
import Pagination from "../../services/Pagination";
import debounce from "lodash/debounce";
import { Link } from "react-router-dom";
import "../../css/sidebar.css";
import {
  showConfirmationDialog,
} from "../../services/alert_service";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [modal, setModal] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [selected, setSelected] = useState(0);
  const [roles, setRoles] = useState([]);
  const [inputTimeout, setInputTimeout] = useState(null);
  const [searchComp, setSearchComp] = useState("");
  const [formValue, setFormValue] = useState({
    role_id: 0,
    name: "",
    mobile_no: "",
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const itemPerPage = 8

  const permissions = JSON.parse(localStorage.getItem("permissions"));
  const userPermission = permissions.find(
    (item) => item.table_name === "users"
  );
  const isAdmin = localStorage.getItem("isAdmin") === "true";

  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const getUsers = async () => {
    setIsLoading(true);
    await getMethod(Apiurl.register, {
      page: currentPage,
      pageSize: itemPerPage,
      search: searchQuery,
    })
      .then((res) => {
        setTotalPages(res.data.total_page);
        setUsers(res.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const delayedSearch = debounce(() => {
    getUsers();
  }, 300);

  const validationRules = {
    role_id: "Please select role type",
    name: "Name is required",
    mobile_no: "Mobile No. is required",
    email: "Email is required",
    // password: "Password is required",
  };

  const validateForm = () => {
    let formIsValid = true;
    const newErrors = {};
    Object.entries(validationRules).forEach(([key, message]) => {
      if (!formValue[key]) {
        newErrors[key] = message;
        formIsValid = false;
      }
    });
    const mobileNoRegex = /^\d{10}$/;
    if (formValue.mobile_no && !mobileNoRegex.test(formValue.mobile_no)) {
      newErrors.mobile_no = "Mobile No must be 10 digits.";
      formIsValid = false;
    }
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (formValue.email && !emailRegex.test(formValue.email)) {
      newErrors.email = "Enter a valid email address.";
      formIsValid = false;
    }
    // const passwordRegex =
    //   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/;
    // if (formValue.password && !passwordRegex.test(formValue.password)) {
    //   newErrors.password =
    //     "Password must be at least 8 characters long and contain at least one letter, one number, and one special character.";
    //   formIsValid = false;
    // }
    setErrors(newErrors);
    return formIsValid;
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    try {
      if (validateForm()) {
        const response = await putMethod(
          `${Apiurl.register}/?id=${selected}`,
          formValue
        );
        getUsers();
        setFormValue({
          role_id: 0,
          name: "",
          mobile_no: "",
          email: "",
          password: "",
        });
        setErrors({});
        setModal(!modal);
      }
    } catch (err) {
    }
  };

  const setEditModal = (id) => {
    setModal(true);
    const selected = users.find((item) => item.id === id);
    setSelected(selected.id);

    setFormValue({
      role_id: selected.role_id,
      name: selected.name,
      mobile_no: selected.mobile_no,
      email: selected.email,
      password: selected.password,
    });
  };

  const handleDelete = async (id) => {
    try {
      const confirmation = await showConfirmationDialog(
        "Are you sure you want to delete ?",
        "",
        "warning"
      );
      if (confirmation) {
        await deleteMethod(`${Apiurl.register}/?id=${id}`)
          .then((res) => {
            getUsers();
          })
          .catch((error) => {

          });
      }
    } catch (error) {
    }
  };

  const getRoles = async () => {
    await getMethod(`${Apiurl.role}?checkForPermission=false`)
      .then((res) => {
        setRoles(res.data.data);
      })
      .catch((error) => {
      });
  };

  useEffect(() => {
    delayedSearch();
  }, [currentPage, searchQuery]);

  const handleSearch = (search) => {
    setSearchComp(search);
    if (inputTimeout) clearTimeout(inputTimeout);
    setInputTimeout(
      setTimeout(() => {
        setSearchQuery(search);
        setCurrentPage(1);
      }, 500)
    );
  };


  useEffect(() => {
    getRoles();
  }, []);

  return (
    <div>
      <Navbar />
      <Modal isOpen={modal} toggle={() => setModal(!modal)}>
        <ModalHeader toggle={() => setModal(!modal)} className="d-block">
          <div className="container-fluid">
            <div className="block-header">
              <div className="row clearfix">
                <div className="col-lg-4 col-md-12 col-sm-12">
                  <h1 style={{ width: "270px" }}>Update User</h1>
                </div>
              </div>
            </div>
            <div className="row clearfix">
              <div className="col-sm-12">
                <div className="">
                  <div className="body">
                    <form onSubmit={handleEditSubmit}>
                      <div className="form-group row justify-content-between align-items-center">
                        <label
                          className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 col-form-label"
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          Role
                          <span className="text-danger" style={{ margin: "4px" }}>*</span>
                        </label>
                        <select
                          name="role_id"
                          value={formValue.role_id}
                          onChange={handleChange}
                          className="form-control input-height col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12"
                          style={{ marginLeft: "8px" }}
                        >
                          <option value="">Select roles...</option>
                          {roles.map((role, i) => (
                            <option key={i} value={role.id}>
                              {role.role}
                            </option>
                          ))}
                        </select>
                      </div>
                      {errors.role_id && (
                        <p className="useredit_err">{errors.role_id}</p>
                      )}
                      <div className="form-group row justify-content-between align-items-center">
                        <label
                          className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 col-form-label"
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          Name
                          <span
                            className="text-danger"
                            style={{ margin: "4px" }}
                          >
                            *
                          </span>
                        </label>
                        <input
                          type="text"
                          value={formValue.name}
                          name="name"
                          className="form-control input-height col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12"
                          onChange={handleChange}
                          style={{ marginLeft: "8px" }}
                          placeholder="Enter Name"
                        />
                      </div>
                      {errors.name && (
                        <p className="useredit_err">{errors.name}</p>
                      )}
                      <div className="form-group row justify-content-between align-items-center">
                        <label
                          className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 col-form-label"
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          Mobile No.
                          <span
                            className="text-danger"
                            style={{ margin: "4px" }}
                          >
                            *
                          </span>
                        </label>
                        <input
                          type="text"
                          name="mobile_no"
                          value={formValue.mobile_no}
                          onChange={handleChange}
                          className="form-control input-height col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12"
                          maxLength={10}
                          pattern="[0-9]*"
                          style={{ marginLeft: "8px" }}
                          placeholder="Enter Mobile Number"
                        />
                      </div>
                      {errors.mobile_no && (
                        <p className="useredit_err">{errors.mobile_no}</p>
                      )}
                      <div className="form-group row justify-content-between align-items-center">
                        <label
                          className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 col-form-label"
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          Email
                          <span
                            className="text-danger"
                            style={{ margin: "4px" }}
                          >
                            *
                          </span>
                        </label>
                        <input
                          type="text"
                          value={formValue.email}
                          name="email"
                          onChange={handleChange}
                          className="form-control input-height col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12"
                          style={{ marginLeft: "8px" }}
                          placeholder="Enter Email"
                        />
                      </div>
                      {errors.email && (
                        <p className="useredit_err">{errors.email}</p>
                      )}
                      <div className="form-group row justify-content-between align-items-center">
                        <label
                          className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 col-form-label"
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          Password
                          <span
                            className="text-danger"
                            style={{ margin: "4px" }}
                          >
                            *
                          </span>
                        </label>
                        <input
                          type="text"
                          // value={formValue.password}
                          name="password"
                          onChange={handleChange}
                          className="form-control input-height col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12"
                          style={{ marginLeft: "8px" }}
                          placeholder="Enter Password"
                        />
                      </div>
                      {/* {errors.password && (
                        <p style={{ color: "red", marginBottom: "0px", fontSize: "13px" }}>{errors.password}</p>
                      )} */}
                      <div className="form-group d-flex justify-content-center">
                        <div className="col-12 text-center mt-2">
                          <button
                            type="submit"
                            className="btn btn-success btn_bg_color"
                            style={{ marginRight: "30px" }}
                          >
                            Submit
                          </button>
                          <div
                            className="btn btn-secondary"
                            style={{ backgroundColor: "grey" }}
                            onClick={() => {
                              setModal(false);
                              setErrors({});
                            }}
                          >
                            Cancel
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalHeader>
      </Modal>

      <div id="main-content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row clearfix">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <h1>Users</h1>
              </div>
              {(isAdmin || userPermission?.create) && (
                <div className="col-lg-8 col-md-12 col-sm-12 text-lg-right">
                  <div className="d-flex align-items-center justify-content-lg-end mt-4 mt-lg-0 flex-wrap vivify pullUp delay-550">
                    <div className="mb-3 mb-xl-0">
                      <Link to="/addusers">
                        <span className="btn btn-dark">Add New User</span>
                      </Link>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-sm-12">
              <div className="card">
                <div className="body">

                  <div className="input-group">
                    <input
                      type="text"
                      name="search"
                      onChange={(e) => {
                        handleSearch(e.target.value);
                      }}
                      value={searchComp}
                      className="form-control"
                      placeholder="Search here..."
                    />
                    <div className="input-group-append">
                      <span className="input-group-text" id="search-mail">
                        <i className="fa fa-search text-info"></i>
                      </span>
                    </div>
                  </div>

                </div>
              </div>
              {isLoading ? (
                <span className="loader"></span>
              ) : (
                <>
                  <div className="table-responsive">
                    <table className="table table-hover table-custom spacing5">
                      <thead>
                        <tr>
                          <th>Sr.No</th>
                          <th>Role</th>
                          <th>Name</th>
                          <th>Mobile No.</th>
                          <th>Email</th>
                          {(isAdmin ||
                            userPermission?.edit ||
                            userPermission?.delete) && <th>Action</th>}
                        </tr>
                      </thead>

                      <tbody>
                        {users.length > 0 ? (
                          users.map((list, i) => (
                            <React.Fragment key={i}>
                              <tr>
                                <td>#{(currentPage - 1) * itemPerPage + 1 + i}</td>
                                <td>{list.role.role}</td>
                                <td>{list.name}</td>
                                <td>{list.mobile_no}</td>
                                <td>{list.email}</td>
                                <td>
                                  {(isAdmin || userPermission?.edit) && (
                                    <button
                                      type="button"
                                      className="btn btn-default btn-sm edit-buton"
                                      title="Edit"
                                      onClick={() => setEditModal(list.id)}
                                    >
                                      <i className="fa fa-pencil text-success edit-button"></i>
                                    </button>
                                  )}
                                  {(isAdmin || userPermission?.delete) && (
                                    <button
                                      type="button"
                                      className="btn btn-default btn-sm"
                                      title="Delete"
                                      onClick={() => handleDelete(list.id)}
                                    >
                                      <i className="fa fa-trash-o text-danger delete-button"></i>
                                    </button>
                                  )}
                                </td>
                              </tr>
                            </React.Fragment>
                          ))) : (
                          <tr>
                            <td style={{ borderTop: "none", textAlign: "center" }} colSpan={6}>No records found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {users.length > 0 &&
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={handlePageChange}
                    />
                  }
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Users;
