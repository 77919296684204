/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { postMethod } from "../../services/base_service";
import { Apiurl } from "../../services/apiRoute";
import "../../css/sidebar.css";

import { Header } from "../utils/NameString";

const Login = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [formValue, setFormValue] = useState({
    mobile_no: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    mobile_no: "",
    password: "",
    form: "",
  });

  const [passwordVisible, setpassordVisible] = useState(false);


  const handleSubmit = async (e) => {
    e.preventDefault();
    let formIsValid = true;
    const newErrors = { ...errors };
    if (!formValue.mobile_no) {
      newErrors.mobile_no = "Mobile No is required.";
      formIsValid = false;
    } else if (formValue.mobile_no.length !== 10) {
      newErrors.mobile_no = "Mobile No must be 10 digits.";
      formIsValid = false;
    } else {
      newErrors.mobile_no = "";
    }
    if (!formValue.password) {
      newErrors.password = "Password is required.";
      formIsValid = false;
    } else {
      newErrors.password = "";
    }
    if (!formIsValid) {
      setErrors(newErrors);
      return;
    }
    try {
      setIsLoading(true);
      const response = await postMethod(Apiurl.login, formValue);
      if (response.status === 200) {
        setIsLoading(false);
        if (
          response.data.permissions[0] &&
          response.data.permissions[0].role_id
        ) {
          localStorage.setItem("role_id", response.data.permissions[0].role_id);
        }
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("isAdmin", response.data.isAdmin);
        localStorage.setItem("username", response.data.name);
        localStorage.setItem(
          "permissions",
          JSON.stringify(response.data.permissions)
        );

        setFormValue({ mobile_no: "", password: "" });
        setErrors({ mobile_no: "", password: "", form: "" });
        navigate("/");
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      const handledError = error;
      if (handledError.error === "User Not Found") {
        setErrors({
          ...newErrors,
          form: "User not found. Please check your credentials.",
        });
        setIsLoading(false);
      } else if (handledError.error === "Validation Error") {
        setErrors({
          ...newErrors,
          form: "Validation Error: " + handledError.message,
        });
        setIsLoading(false);
      } else {
        setErrors({
          ...newErrors,
          form: handledError.message === undefined || null ? "Error occurred: Something went wrong, please try again" : "Error occurred: " + handledError.message,
        });
        setIsLoading(false);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "mobile_no" && value.length > 10) {
      return;
    }
    setFormValue({ ...formValue, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  return (
    <div>
      <div id="body" className="theme-green">
        <div className="auth-main">
          <div className="auth_div vivify fadeIn">
            <div className="auth_brand">
              <div className="navbar-brand">
                <img
                  src={Header.Logo_image}
                  width="50"
                  className="d-inline-block align-top mr-2"
                  alt="photos"
                />
                {Header.Main_title}
              </div>
            </div>
            <div className="card">
              <div className="header">
                <p className="lead">Login to your account</p>
              </div>
              <div className="body">
                <form className="form-auth-small" onSubmit={handleSubmit}>
                  <div className="form-group c_form_group">
                    {isLoading ? <span className="loader"></span> : ""}
                    <label>Mobile No</label>
                    <input
                      type="text"
                      value={formValue.mobile_no}
                      name="mobile_no"
                      onChange={handleChange}
                      className="form-control"
                      placeholder="Enter your mobile number"
                    />
                  </div>
                  {errors.mobile_no && (
                    <p className="loginerrorr">{errors.mobile_no}</p>
                  )}
                  <div>
                    <div className="form-group c_form_group" >
                      <label>Password</label>
                      <div style={{ position: 'relative' }}>
                      <input
                        type={passwordVisible ? "text" : "password"}
                        name="password"
                        value={formValue.password}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Enter your password"
                      />
                       <div className="" style={{ position: 'absolute', cursor: "pointer", bottom: 0, right: 0 }} onClick={() => setpassordVisible(!passwordVisible)}>
                      {passwordVisible ?
                        <i className="fa fa-eye" aria-hidden="true"></i> : <i className="fa fa-eye-slash" aria-hidden="true"></i>}
                    </div>
                      </div>
                    </div>
                   
                  </div>
                  {errors.password && (
                    <p className="loginerrorr">{errors.password}</p>
                  )}
                  {errors.form && <p style={{ color: "red" }}>{errors.form}</p>}
                  <button
                    type="submit"
                    className="btn btn-dark btn-lg btn-block"
                  >
                    LOGIN
                  </button>
                  {/* <div className="bottom">
                    <span>
                      Don't have an account?
                      <Link to="/addusers">Register</Link>
                    </span>
                  </div> */}
                </form>
              </div>
            </div>
          </div>
          <div className="animate_lines">
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
