import React, { useEffect, useRef, useState } from 'react';
import { getMethod } from '../../services/base_service';
import { debounce } from 'lodash';
import Navbar from './Navbar';
import Pagination from '../../services/Pagination';
import { Apiurl } from '../../services/apiRoute';

const AllAccountTransactionList = () => {


    const [tableData, setTableData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    // const [totalPages, setTotalPages] = useState(1);
    // const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState("");
    const [inputTimeout, setInputTimeout] = useState(null);
    const [searchComp, setSearchComp] = useState("");

    // const [order, setOrder] = useState("")
    // const itemPerPage = 8


    const getAllAccountTransactionList = async () => {
        try {
            setIsLoading(true);
            // const response = await getMethod(`${Apiurl.All_terminal_list}?page=${currentPage}&limit=${itemPerPage}&search=${searchQuery}&account_no=${order}`);
            const response = await getMethod(`${Apiurl.All_terminal_list}?&account_no=${searchQuery}&page=$1&limit=100000`);
            const allTransactins = response.data.data.map((account) => {
                return [...account.deposit_withdrawals, ...account.to_mt5usdt_transaction, ...account.from_mt5usdt_transaction]
            })
            setTableData(allTransactins);
            // setTotalPages(response.data.total_page);
            setIsLoading(false);
        } catch (error) {

        } finally {
            setIsLoading(false);
        }
    };

    // const handlePageChange = (newPage) => {
    //     setCurrentPage(newPage);
    // };

    const delayedSearch = debounce(() => {
        getAllAccountTransactionList();
    }, 300);

    useEffect(() => {
        delayedSearch();
    }, [searchQuery]);


    const handleSearch = (search) => {
        setSearchComp(search);
        if (inputTimeout) clearTimeout(inputTimeout);
        setInputTimeout(
            setTimeout(() => {
                setSearchQuery(search);
                // setCurrentPage(1);
            }, 500)
        );
    };

    return (
        <div>
            <Navbar />

            <div id="main-content">
                <div className="container-fluid">
                    <div className="block-header">
                        <div className="row clearfix">
                            <div className="col-lg-4 col-md-12 col-sm-12">
                                <h1>All Account Transaction List</h1>
                            </div>
                            {/* <div className="col-lg-8 col-md-12 col-sm-12 text-lg-right">
                                <div className="d-flex align-items-center justify-content-lg-end mt-4 mt-lg-0 flex-wrap vivify pullUp delay-550">
                                    <div className="mb-3 mb-xl-0">
                                        <Link to="/">
                                            <span className="btn btn-dark">Set path</span>
                                        </Link>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="row clearfix">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="body">

                                    <div className="input-group">
                                        <input
                                            type="text"
                                            name="search"
                                            onChange={(e) => { handleSearch(e.target.value); }}
                                            value={searchComp}
                                            className="form-control"
                                            placeholder="Search here..."
                                        />
                                        <div className="input-group-append">
                                            <span className="input-group-text" id="search-mail">
                                                <i className="fa fa-search text-info"></i>
                                            </span>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            {isLoading ? (
                                <span className="loader"></span>
                            ) : (
                                <>

                                    <div className="table-responsive">
                                        <table className="table table-hover table-custom spacing5">
                                            <thead>
                                                <tr>
                                                    <th>Sr.No</th>
                                                    <th>Date</th>
                                                    <th>Account No</th>
                                                    <th>From Account</th>
                                                    <th>To Account</th>
                                                    <th>Terminal Name</th>
                                                    <th>MT5_USDT</th>
                                                    <th>Rate</th>
                                                    <th>Amount</th>
                                                    <th>Currency Type</th>
                                                    <th>User Name</th>
                                                    <th>Transaction Id</th>
                                                    <th>Transaction By</th>
                                                    <th>Transaction Type</th>
                                                    <th>Note</th>

                                                </tr>
                                            </thead>

                                            <tbody>
                                                {tableData.length > 0 ? (
                                                    tableData[0].map((list, i) => (
                                                        <React.Fragment key={i}>
                                                            <tr>
                                                                <td>#{i + 1} </td>
                                                                <td>{list?.date ? list?.date : "-"}</td>
                                                                <td>{list?.account_no ? list?.account_no : "-"}</td>
                                                                <td>{list?.from_account_no ? list?.from_account_no : "-"}</td>
                                                                <td>{list?.to_account_no ? list?.to_account_no : "-"}</td>
                                                                <td>{list?.terminal?.name ? list?.terminal?.name : "-"}</td>
                                                                <td>{typeof (list.mt5_usdt) === "string" ? atob(list.mt5_usdt) : list.mt5_usdt}</td>
                                                                <td>{list.rate ? list.rate : "-"}</td>
                                                                <td>{list.amount ? atob(list.amount) : "-"}</td>
                                                                <td>{list?.currency?.name ? list?.currency?.name : "-"}</td>
                                                                <td>{list?.users?.name}</td>
                                                                <td>{list?.transaction_id ? list?.transaction_id : "-"}</td>
                                                                <td>{list?.transaction_by_users?.name ? list?.transaction_by_users?.name : "-"}</td>
                                                                <td>{list?.type ? list.type === "Dr" ? "Deposit" : "Withdrawal" : "MT5 USDT Transfer"}</td>
                                                                <td style={{ whiteSpace: "break-spaces" }}>{list?.note || "-"}</td>
                                                            </tr>
                                                        </React.Fragment>
                                                    ))) : (
                                                    <tr>
                                                        <td style={{ borderTop: "none", textAlign: "center" }} colSpan={15}>
                                                            No Records Found !!! try Entering <span className='font-italic' style={{ textDecoration: "underline" }}>Exact Account No</span> in Search box
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                    {/* <Pagination
                                        currentPage={currentPage}
                                        totalPages={totalPages}
                                        onPageChange={handlePageChange}
                                    /> */}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AllAccountTransactionList;
