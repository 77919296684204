import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Navbar from "../Navbar";
import { postMethod, getMethod } from "../../../services/base_service";
import { Apiurl } from "../../../services/apiRoute";

const AddDueBills = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm();
  const [agencies, setAgencies] = useState([]);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currencies, setCurrencies] = useState([])

  const handleAddDueBill = async (data) => {
    try {
      setIsLoading(true);
      let addDuePayment = {};
      if (data.payment_status === 'pending') {
        addDuePayment = {
          agency_id: data.agency_id,
          date: data.date,
          bill_no: data.bill_no,
          currency_id: data.currency_id,
          amount: data.amount,
          payment_status: data.payment_status
        };
      } else {
        addDuePayment = {
          agency_id: data.agency_id,
          date: data.date,
          bill_no: data.bill_no,
          currency_id: data.currency_id,
          amount: data.amount,
          adjustment_amount: data.adjustment_amount,
          credit_from: data.credit_from,
          payment_type: data.payment_type,
          payment_status: data.payment_status
        };
      }
      const response = await postMethod(Apiurl.dueIncome, addDuePayment);
      setIsLoading(false);
      reset();
      document.getElementById("agency_id").value = "";
      document.getElementById("payment_status").value = "";
      document.getElementById("credit_from").value = "";
      document.getElementById("payment_type").value = "";
      document.getElementById("currency_id").value = "";
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getAgencies = async () => {
    setIsLoading(true);
    await getMethod(`${Apiurl.agency}`)
      .then((res) => {
        setAgencies(res.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const getUsers = async () => {
    await getMethod(`${Apiurl.filter_user_list}?checkForPermission=false`)
      .then((res) => {
        setUsers(res.data.data);
      })
      .catch((error) => {
      });
  };
  const getCurrencies = async () => {
    await getMethod(`${Apiurl.currency}/list?checkForPermission=false`).then((data) => {
      setCurrencies(data.data.currency)
    }).catch((error) => {
    })
  }

  useEffect(() => {
    getAgencies();
    getCurrencies()
    getUsers();
  }, []);

  return (
    <div>
      <Navbar />
      <div id="main-content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row clearfix">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <h1>Add Due Bill</h1>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-sm-12">
              <div className="card">
                <div className="body">
                  <form onSubmit={handleSubmit(handleAddDueBill)}>
                    <div className="form-group row">
                      {isLoading ? <span className="loader"></span> : ""}
                      <label className="col-sm-2 col-form-label">
                        Agency
                        <span className="text-danger">*</span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <select
                          id="agency_id"
                          name="agency_id"
                          className="form-control"
                          // style={{ marginLeft: "8px" }}
                          {...register("agency_id", {
                            required: "Agency selection is Required.",
                          })}
                        >
                          <option value="" disabled selected>
                            Select Agency...
                          </option>
                          {agencies.map((item, i) => (
                            <option key={i} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                        {errors.agency_id && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.agency_id.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Date
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <input
                          type="date"
                          {...register("date", {
                            required: "Date is Required.",
                          })}
                          className="form-control"
                          name="date"
                          id="inputEmail3"
                        />
                        {errors.date && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.date.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Bill No.
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <input
                          type="text"
                          {...register("bill_no", {
                            required: "Bill No. is Required.",
                          })}
                          className="form-control"
                          name="bill_no"
                          id="inputEmail2"
                          placeholder="Enter Bill Number"
                        />
                        {errors.bill_no && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.bill_no.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Currency Type
                        <span className="text-danger">*</span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <select
                          id="currency_id"
                          name="currency_id"
                          className="form-control"
                          {...register("currency_id", {
                            required: "Currency Type is Required.",
                          })}
                        >
                          <option value="" disabled selected>
                            Select Currency Type...
                          </option>
                          {currencies.map((item,i) => (
                            <option key={i} value={item.id}>{item.name}</option>
                          ))}
                        </select>
                        {errors.currency_id && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.currency_id.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Amount
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <input
                          type="number"
                          {...register("amount", {
                            required: "Amount is Required.",
                          })}
                          className="form-control"
                          name="amount"
                          id="inputEmail2"
                          placeholder="Enter Amount"
                        />
                        {errors.amount && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.amount.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Payment Status
                        <span className="text-danger">*</span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <select
                          id="payment_status"
                          name="payment_status"
                          className="form-control"
                          // style={{ marginLeft: "8px" }}
                          {...register("payment_status", {
                            required: "Payment Status is Required.",
                          })}

                        >
                          <option value="" disabled selected>
                            Select Payment Status...
                          </option>
                          <option value="pending">Pending</option>
                          <option value="completed">Completed</option>
                        </select>
                        {errors.payment_status && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.payment_status.message}
                          </p>
                        )}
                      </div>
                    </div>

                    {watch("payment_status") === "completed" && (
                      <>
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">
                            Received By
                            <span className="text-danger">*</span>
                          </label>
                          <div className="col-md-6 col-sm-10">
                            <select
                              id="credit_from"
                              name="credit_from"
                              className="form-control"
                              {...register("credit_from", {
                                required: "Received by is Required.",
                              })}
                            >
                              <option value="" disabled selected>
                                Select Payer of this Bill...
                              </option>
                              {users.map((item, i) => (
                                <option key={i} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                            {errors.credit_from && (
                              <p
                                style={{
                                  color: "red",
                                  marginBottom: "0px",
                                  fontSize: "13px",
                                }}
                              >
                                {errors.credit_from.message}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">
                            Payment Type
                            <span className="text-danger">*</span>
                          </label>
                          <div className="col-md-6 col-sm-10">
                            <select
                              id="payment_type"
                              name="payment_type"
                              className="form-control"
                              // style={{ marginLeft: "8px" }}
                              {...register("payment_type", {
                                required: "Payment Type is Required.",
                              })}
                            >
                              <option value="" disabled selected>
                                Select Payment Type...
                              </option>
                              <option value="cash">Cash</option>
                              <option value="bank">Bank</option>
                            </select>
                            {errors.payment_type && (
                              <p
                                style={{
                                  color: "red",
                                  marginBottom: "0px",
                                  fontSize: "13px",
                                }}
                              >
                                {errors.payment_type.message}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">
                            Adjustment Amount
                            <span
                              className="text-danger"
                              style={{ margin: "4px" }}
                            >
                              *
                            </span>
                          </label>
                          <div className="col-md-6 col-sm-10">
                            <input
                              type="text"
                              {...register("adjustment_amount", {
                                required: "Adjustment Amount is Required.",
                              })}
                              className="form-control"
                              name="adjustment_amount"
                              id="inputEmail2"
                              placeholder="Enter Adjustment Amount"
                            />
                            {errors.adjustment_amount && (
                              <p
                                style={{
                                  color: "red",
                                  marginBottom: "0px",
                                  fontSize: "13px",
                                }}
                              >
                                {errors.adjustment_amount.message}
                              </p>
                            )}
                          </div>
                        </div>
                        {watch("payment_type") === "bank" && (
                          <div className="form-group row">
                            <label className="col-sm-2 col-form-label">
                              Note
                              <span
                                className="text-danger"
                                style={{ margin: "4px" }}
                              >
                                *
                              </span>
                            </label>
                            <div className="col-md-6 col-sm-10">
                              <input
                                type="text"
                                {...register("note", {
                                  required: "Note is Required.",
                                })}
                                className="form-control"
                                name="note"
                                id="inputEmail2"
                                placeholder="Enter Note"
                              />
                              {errors.note && (
                                <p
                                  style={{
                                    color: "red",
                                    marginBottom: "0px",
                                    fontSize: "13px",
                                  }}
                                >
                                  {errors.note.message}
                                </p>
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    )}

                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label"></label>
                      <div className="col-md-6 col-sm-10">
                        <button
                          type="submit"
                          className="btn btn-success btn_bg_color"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddDueBills;
